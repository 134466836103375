import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import debounce from 'lodash.debounce'

import { ControlMenu, Button, Input, InputLabeled, SelectLabeled } from '.'
import {
  getFieldComponents,
  getPropFieldComponents,
  isEmpty,
} from '../../utils'

import { productData } from '../../data'

import { SearchIcon } from '../../assets/icons'
import { leftFlexColumn, leftFlexRow, grid12WithGap } from '../../styles/mixins'
//import InputLabeled from './inputLabeled'

const ProductForm = ({
  className,
  upid,
  fields,
  logisticsFields,
  inputFormat,
  onSubmit = () => {},
}) => {
  const { handleSubmit, control, watch, register } = useForm()
  const { fields: textFields, append: appendTextProp } = useFieldArray({
    control,
    name: 'textProp',
  })
  const { fields: numFields, append: appendNumProp } = useFieldArray({
    control,
    name: 'numProp',
  })

  const [searchValue, setSearchValue] = useState('')
  const [isDetailedPropsVisible, setIsDetailedPropsVisible] = useState(true)

  const textPropRef = useRef()
  const numPropRef = useRef()

  const submitHandler = (data) => {
    onSubmit?.(data)
  }

  const productType = upid
    ? productData.find((product) => product.upid.value === upid).productType
        .value
    : ''
  const watchProductType = watch('productType', {
    value: productType,
    label: '',
  })

  useEffect(() => {
    setSearchValue('')
  }, [watchProductType.value])

  const propFieldComponents = useMemo(
    () => getPropFieldComponents(watchProductType.value, inputFormat),
    [watchProductType.value]
  )

  const filteredPropFieldComponents = () => {
    if (isEmpty(watchProductType.value)) return []

    return propFieldComponents.filter(
      (field) => field.prop.props.label.toLowerCase().indexOf(searchValue) >= 0
    )
  }

  const searchDebounced = useCallback(
    debounce((searchValue) => {
      setSearchValue(searchValue)
    }, 500),
    []
  )

  return (
    <ProductFormStyled
      className={className}
      isDetailedPropsVisible={isDetailedPropsVisible}
    >
      <form onSubmit={handleSubmit(submitHandler)}>
        {fields.map((fieldRow) => {
          const options = getFieldComponents(fieldRow, inputFormat).map(
            (fieldItem) => {
              const { component: Component, ...option } = fieldItem
              option.component = (
                <Controller
                  name={option.props.name}
                  control={control}
                  defaultValue=''
                  render={({ onChange, value }) => (
                    <Component
                      onChange={onChange}
                      {...option.props}
                      value={value}
                    />
                  )}
                />
              )
              return option
            }
          )
          return <ControlMenu options={options} />
        })}
        {!isEmpty(watchProductType.value) && (
          <>
            <div id='detailedPropsHeader'>
              <h3>Detailed Properties</h3>
              <Button
                text={isDetailedPropsVisible ? 'Hide' : 'Show'}
                onClick={() =>
                  setIsDetailedPropsVisible(!isDetailedPropsVisible)
                }
              />
            </div>
            <div id='search'>
              <InputLabeled
                label='Search'
                className='colSpan3'
                onChange={(e) => searchDebounced(e.target.value)}
                icon={SearchIcon}
              />
            </div>
            <div className='detailedProps'>
              {propFieldComponents.map((fieldItem) => {
                const {
                  prop: { component: PropComponent, props: propProps },
                  unit: unitItem,
                } = fieldItem
                const {
                  component: UnitComponent,
                  props: unitProps,
                } = unitItem || {
                  component: null,
                  props: null,
                }

                return (
                  <div
                    className={
                      propProps.label.toLowerCase().indexOf(searchValue) >= 0
                        ? 'propContainer'
                        : 'hidden'
                    }
                  >
                    <Controller
                      name={propProps.name}
                      control={control}
                      defaultValue=''
                      render={({ onChange, value }) => (
                        <PropComponent
                          onChange={onChange}
                          value={value}
                          {...propProps}
                        />
                      )}
                    />
                    {!isEmpty(unitItem) && (
                      <Controller
                        name={unitProps.name}
                        control={control}
                        defaultValue=''
                        render={({ onChange, value }) => (
                          <UnitComponent
                            onChange={onChange}
                            value={value}
                            {...unitProps}
                          />
                        )}
                      />
                    )}
                  </div>
                )
              })}
            </div>
            <div id='customPropInput'>
              <div className='inputContainer colSpan4'>
                <InputLabeled
                  ref={textPropRef}
                  name='customTextProp'
                  label='Text Property'
                />
                <Button
                  type='button'
                  text='Add'
                  onClick={() => {
                    // TODO: Check whether name already exists
                    // TODO: Try to use controlled component
                    if (isEmpty(textPropRef.current.value)) return
                    appendTextProp({
                      label: textPropRef.current.value,
                      name: textPropRef.current.value,
                      value: '',
                    })
                    textPropRef.current.value = null
                  }}
                />
              </div>
              <div className='inputContainer colSpan4'>
                <InputLabeled
                  ref={numPropRef}
                  name='customNumProp'
                  label='Numerical Property'
                />
                <Button
                  type='button'
                  text='Add'
                  onClick={() => {
                    // TODO: Check whether name already exists
                    // TODO: Try to use controlled component
                    if (isEmpty(numPropRef.current.value)) return
                    appendNumProp({
                      label: numPropRef.current.value,
                      name: numPropRef.current.value,
                      value: 0,
                      units: '',
                    })
                    numPropRef.current.value = null
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!isEmpty(textFields) && (
          <div className='detailedProps'>
            {textFields.map((field, i) => (
              <div className='propContainer' key={field.id}>
                <Controller
                  name={`textProp[${i}].value`}
                  control={control}
                  defaultValue={field.value}
                  render={({ onChange, value }) => (
                    <InputLabeled
                      type='text'
                      className='colSpan4'
                      onChange={onChange}
                      value={value}
                      label={field.label}
                    />
                  )}
                />
                <input
                  type='hidden'
                  name={`textProp[${i}].label`}
                  ref={register()}
                  defaultValue={field.label}
                />
                <input
                  type='hidden'
                  name={`textProp[${i}].name`}
                  ref={register()}
                  defaultValue={field.name}
                />
              </div>
            ))}
          </div>
        )}
        {!isEmpty(numFields) && (
          <div className='detailedProps'>
            {numFields.map((field, i) => (
              <div className='propContainer colSpan4' key={field.id}>
                <Controller
                  name={`numProp[${i}].value`}
                  control={control}
                  defaultValue={field.value}
                  render={({ onChange, value }) => (
                    <InputLabeled
                      type='number'
                      onChange={onChange}
                      value={value}
                      label={field.label}
                    />
                  )}
                />
                <Controller
                  name={`numProp[${i}].units`}
                  control={control}
                  defaultValue={''}
                  render={({ onChange, value }) => (
                    <SelectLabeled
                      onChange={onChange}
                      value={value}
                      label='Units'
                      options={[]}
                    />
                  )}
                />
                <input
                  type='hidden'
                  name={`textProp[${i}].label`}
                  ref={register()}
                  defaultValue={field.label}
                />
                <input
                  type='hidden'
                  name={`textProp[${i}].name`}
                  ref={register()}
                  defaultValue={field.name}
                />
              </div>
            ))}
          </div>
        )}
        {!isEmpty(logisticsFields) && (
          <ControlMenu
            options={getFieldComponents(logisticsFields, inputFormat).map(
              (fieldItem) => {
                const { component: Component, ...option } = fieldItem
                option.component = (
                  <Controller
                    name={option.props.name}
                    control={control}
                    defaultValue=''
                    render={({ onChange, value }) => (
                      <Component
                        onChange={onChange}
                        value={value}
                        {...option.props}
                      />
                    )}
                  />
                )
                return option
              }
            )}
          />
        )}
        <Button type='submit' theme='green' text='Confirm' />
      </form>
    </ProductFormStyled>
  )
}

const ProductFormStyled = styled.div`
  ${leftFlexColumn}
  width: 100%;

  form {
    ${leftFlexColumn}
    width: 100%;

    > *:not(:last-child) {
      margin-bottom: 2em;
    }

    button:last-child {
      align-self: center;
    }

    #search {
      ${grid12WithGap}
      width: 100%;
      margin-top: -2rem;
    }

    #customPropInput {
      ${grid12WithGap}
      ${(props) => props.isDetailedPropsVisible && 'display: none;'}
      grid-template-columns: repeat(
        12,
        calc(100% / 12 - 11 / 12 * 2 * var(--grid-col-gap))
      );
      width: 100%;
      margin-bottom: 4rem;
      column-gap: calc(2 * var(--grid-col-gap));

      .inputContainer {
        ${leftFlexRow}
        align-items: flex-end;

        > div:first-child {
          margin-right: 0.5rem;
        }

        > button {
          align-self: flex-end;
          width: auto;
          height: calc(var(--input-height) + 2px);
          border-radius: var(--border-radius);
        }
      }
    }

    input[type='hidden'],
    .hidden {
      display: none;
    }

    #detailedPropsHeader {
      ${leftFlexRow}
      margin-top: 2rem;
      margin-bottom: 4rem;

      h3 {
        margin: 0;
        margin-right: 2rem;
      }

      button {
        width: 5rem;
        height: calc(var(--input-height) + 2px);
        border-radius: var(--border-radius);
      }
    }

    .detailedProps {
      ${grid12WithGap}
      ${(props) => props.isDetailedPropsVisible && 'display: none;'}
      grid-template-columns: repeat( 12, calc(100% / 12 - 11 / 12 * 2 * var(--grid-col-gap)) );
      width: 100%;
      column-gap: calc(2 * var(--grid-col-gap));

      > .propContainer {
        ${leftFlexRow}
        grid-column: span 4;

        > *:nth-child(2) {
          align-self: flex-end;
          margin-left: 0.5rem;
        }
      }
    }
  }
`

export default ProductForm
