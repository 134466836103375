import React from 'react'
import styled from 'styled-components'

import { centerFlexColumn } from '../../styles/mixins'

import { ArrowRightIcon } from '../../assets/icons'

const FilterButton = ({
  className,
  text,
  color,
  onClick,
  isActive = false,
}) => {
  return (
    <FilterButtonStyled
      className={className}
      color={color}
      isActive={isActive}
      onClick={onClick}
    >
      <p>{text}</p>
      <div id='bottom'>
        <ArrowRightIcon />
        <hr />
      </div>
    </FilterButtonStyled>
  )
}

const FilterButtonStyled = styled.button`
  ${centerFlexColumn}

  justify-content: space-between;
  width: 15rem;
  height: 10rem;
  padding: 1rem;
  transition: 0.25s ease-in-out;
  border: none;
  background-color: ${(props) =>
    props.isActive ? `var(--color-${props.color})` : `var(--color-white)`};
  color: ${(props) =>
    props.isActive ? `var(--color-white)` : `var(--color-dark-grey)`};
  font-family: var(--font-primary);
  font-size: var(--font-size-mediumer);
  font-weight: var(--font-weight-bold);

  cursor: pointer;

  &:active,
  :focus {
    border: none;
    outline: none;
  }

  p {
    align-self: flex-start;
    margin: 0;
    text-align: left;
  }

  #bottom {
    ${centerFlexColumn}
    width: 100%;

    svg {
      align-self: flex-end;
      margin: 0.5rem;
      margin-bottom: 1rem;
      transform: rotate(90deg);

      path {
        fill: ${(props) =>
          props.isActive ? `var(--color-white)` : `var(--color-dark-grey)`};
      }
    }

    hr {
      width: 100%;
      height: 5px;
      margin: 0;
      border-style: none;
      background-color: ${(props) =>
        props.isActive ? `var(--color-white)` : `var(--color-${props.color})`};
    }
  }
`

export default FilterButton
