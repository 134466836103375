import React from 'react'
import styled from 'styled-components'

import { centerFlexRow } from '../../styles/mixins.js'
import { isEmpty } from '../../utils'

const PlainButton = ({
  className,
  type = 'button',
  text,
  theme,
  icon,
  hasIconLeft = true,
  onClick,
  isDisabled = false,
}) => {
  const Icon = icon
  return (
    <PlainButtonStyled
      className={className}
      type={type}
      onClick={onClick}
      hasIconLeft={hasIconLeft}
      theme={theme}
      icon={icon}
      hasText={!isEmpty(text)}
      disabled={isDisabled}
    >
      {hasIconLeft && Icon && <Icon />}
      {text && <span>{text}</span>}
      {!hasIconLeft && Icon && <Icon />}
    </PlainButtonStyled>
  )
}

const PlainButtonStyled = styled.button`
  ${centerFlexRow}
  box-sizing: border-box;
  height: 2rem;
  padding: 0;
  border: none;
  border-radius: var(--border-radius);
  background-color: transparent;
  color: var(--color-black);
  font-family: var(--font-primary);
  font-weight: var(--font-weight-bold);
  cursor: pointer;

  & :active,
  :focus {
    border: none;
    outline: none;
  }

  svg {
    ${(props) => props.hasText && 'transform: scale(0.75);'}

    path {
      fill: var(--color-black);
      fill-opacity: 1;
    }
  }

  span {
    ${(props) =>
      props.hasIconLeft ? `margin-left: 0.25rem` : `margin-right: 0.25rem`};
    ${(props) => !props.icon && `margin: 0`}
  }
`

export default PlainButton
