import React from 'react'
import styled from 'styled-components'
import { default as ReactSelect } from 'react-select'

import { getValue, getSelected, styles as selectStyles } from './selectUtils'

const Select = ({
  onChange,
  blurInputOnSelect = true,
  autoFocus = false,
  className,
  name,
  value,
  defaultValue,
  placeholder,
  isMulti,
  options = [],
  isDisabled = false,
  styles,
}) => {
  return (
    <ReactSelect
      className={className}
      name={name}
      {...getValue(value)}
      selected={getSelected()}
      defaultValue={defaultValue}
      isMulti={isMulti}
      placeholder={placeholder}
      onChange={onChange}
      blurInputOnSelect={blurInputOnSelect}
      autoFocus={autoFocus}
      options={options}
      isDisabled={isDisabled}
      styles={styles || selectStyles}
    />
  )
}

export default Select
