import React from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'

import { Page, Section, Line } from '../../components/layout'
import { Button, BreadCrumb } from '../../components/controls'
import Properties from './properties'

import { leftFlexRow, grid12WithGap, lightContainer } from '../../styles/mixins'
import { productData as products } from '../../data'

import { DocumentIcon, ArrowRightIcon } from '../../assets/icons'

const ProductPage = ({ className }) => {
  const { upid } = useParams()
  const history = useHistory()
  const productData = products.find((product) => product.upid.value === upid)
  const {
    productName,
    analogs,
    image,
    productDescription,
    physicalState,
    physicalForm,
    chemicalClass,
    documents,
    msds,
    scientificDescription,
    storageAndHandling,
    industries,
    useCases,
    valderaLabels,
    ...properties
  } = productData

  const paths = [
    {
      path: '/search',
      name: 'Search',
    },
    {
      path: `/search/product/${upid}`,
      name: productName.value,
    },
  ]

  const handleRequest = () => {
    history.push(`/search/product/${upid}/request`)
  }

  return (
    <ProductPageStyled className={className}>
      <Section>
        <BreadCrumb paths={paths} />
        <div id='header'>
          <h2>{productName.value}</h2>
          <Button
            text='Request'
            icon={ArrowRightIcon}
            hasIconLeft={false}
            theme='red'
            onClick={handleRequest}
          />
        </div>
        <Line isThicc={true} />
        <div id='headerInfo'>
          <div>
            <span>
              <strong>{`Analogs: `}</strong>
            </span>
            {analogs.value.join(', ')}
          </div>
          <div>
            <span>
              <strong>{`Product ID: `}</strong>
            </span>
            {upid}
          </div>
        </div>
        <div id='introInfo'>
          {image ? (
            <>
              <img
                alt={productName.value}
                src={image.value}
                className='colSpan4'
              />
              <p className='colSpan8'>{productDescription.value}</p>
            </>
          ) : (
            <>
              <p className='colSpan12'>{productDescription.value}</p>
            </>
          )}
        </div>
        <div id='specifications'>
          <div id='primary'>
            <div id='primaryInfo' className='colSpan12'>
              <h3>Specifications</h3>
              <div>
                <h4>Physical State</h4>
                <p>{physicalState.value}</p>
              </div>
              <div>
                <h4>Physical Form</h4>
                <p>{physicalForm.value}</p>
              </div>
              <div>
                <h4>Chemical Class</h4>
                <p>{chemicalClass.value}</p>
              </div>
              <div>
                <h4>Manuals &amp; Documents</h4>
                <a href={documents.value}>
                  <DocumentIcon />
                </a>
              </div>
              <div>
                <h4>MSDS</h4>
                <a href={msds.value}>
                  <DocumentIcon />
                </a>
              </div>
            </div>
            <div id='buttons' className='colSpan4'></div>
          </div>
        </div>
        <Section className='secondaryInfo'>
          <div>
            <h3>Scientific Description</h3>
            <p>{scientificDescription.value}</p>
          </div>
          <div>
            <h3>Storage &amp; Handling</h3>
            <p>{storageAndHandling.value.join(', ')}</p>
          </div>
          <div>
            <h3>Properties</h3>
            <Properties properties={properties} />
          </div>
          <div className='tagContainer' id='industries'>
            <h3>Industries</h3>
            {industries.value.map((industry) => (
              <div className='tag'>{industry}</div>
            ))}
          </div>
          <div className='tagContainer' id='applications'>
            <h3>Applications</h3>
            {useCases.value.map((application) => (
              <div className='tag'>{application}</div>
            ))}
          </div>
          <div className='tagContainer' id='valderaLabels'>
            <h3>Valdera Labels</h3>
            {valderaLabels.value.map((label) => (
              <div className='tag'>{label}</div>
            ))}
          </div>
          <div>
            <h3>Literature &amp; Additonal Information</h3>
          </div>
        </Section>
      </Section>
    </ProductPageStyled>
  )
}

const ProductPageStyled = styled(Page)`
  #header,
  #headerInfo {
    ${leftFlexRow}
    justify-content: space-between;

    > * {
      margin: 0;
    }
  }

  #introInfo {
    ${grid12WithGap}
    height: 10rem;

    img {
      width: 100%;
      height: 10rem;
      object-fit: cover;
    }

    p {
      margin: 0;
    }
  }

  .tagContainer {
    ${grid12WithGap}
    margin-bottom: 2rem;

    h3 {
      grid-column: span 12;
      margin: 0;
    }
  }

  .tag {
    ${lightContainer}
    grid-column: span 3;
    padding: 0.5rem;
    text-align: center;
  }

  #specifications {
    #primary {
      ${grid12WithGap}

      #primaryInfo {
        ${grid12WithGap}
        ${lightContainer}
        padding: 1.5rem;

        h3 {
          margin: 0;
          margin-bottom: 1rem;
        }

        h3:first-child {
          grid-column: span 12;
          margin: 0;
          margin-bottom: 1rem;
        }

        > * {
          grid-column: span 4;
        }
      }
    }
  }

  .secondaryInfo {
    padding: 2rem 10rem;

    > div > div {
      margin-bottom: 3rem;
    }

    h3 {
      margin-bottom: 1.5rem;
    }

    #industries .tag {
      border-color: var(--color-black);
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
`

export default ProductPage
