import React from 'react'
import styled from 'styled-components'

import { Button, InputEnter } from '../../components/controls'

import { topAngleRight } from '../../styles/mixins'
import { ArrowRightIcon } from '../../assets/icons'

const NewsletterSection = ({ className }) => {
  return (
    <NewsletterSectionStyled id='newsletter' className={className}>
      {' '}
      <div className='content column'>
        <h1>Sign up for our newsletter</h1>
        <Button
          text='Learn more'
          hasIconLeft={false}
          icon={ArrowRightIcon}
          theme='black'
          onClick={() => {
            window.open('mailto:info@valdera.com')
          }}
        />
        {/*<InputEnter placeholder='Email' />*/}
      </div>
    </NewsletterSectionStyled>
  )
}

const NewsletterSectionStyled = styled.div`
  ${topAngleRight}
  height: 50rem;
  background-color: var(--color-white);

  .content {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 20rem;
    padding: 4rem 12rem;
    background-color: var(--color-yellow);

    > div {
      min-width: 20rem;
      height: 3rem;
      border: solid 1px var(--color-black);
      border-radius: 1.5rem;

      input[type='text'] {
        padding-left: 1rem;
        font-size: var(--font-size-medium);
      }
    }

    /*
    button {
      height: inherit;
      padding: 0 1rem;
      border-radius: 0;
      background-color: var(--color-black);
      path {
        fill: var(--color-white);
      }
    }
    */
  }

  @media screen and (max-width: 1200px) {
    .content {
      padding: 4rem 6rem;
    }
  }

  @media screen and (max-width: 767px) {
    height: 40rem;

    h1 {
      font-size: var(--font-size-large);
    }

    .content {
      padding: 4rem 2rem;

      > div {
        height: 2.5rem;

        input[type='text'] {
          padding-left: 0.75rem;
          font-size: var(--font-size-small);
        }
      }
    }
  }
`

export default NewsletterSection
