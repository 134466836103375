import { css } from 'styled-components'

export const clearLinkFormat = css`
  color: inherit;
  text-decoration: none;

  :hover {
    color: inherit;
    text-decoration: none;
  }

  :visited {
    color: inherit;
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }
`

export const centerFlexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const leftFlexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const centerFlexColumn = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const leftFlexColumn = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const clearBorder = css`
  border: none;
  border-radius: 0;
  outline: none;
`

export const grid12WithGap = css`
  display: grid;
  grid-template-columns: repeat(
    12,
    calc(100% / 12 - 11 / 12 * var(--grid-col-gap))
  );
  gap: var(--grid-row-gap) var(--grid-col-gap);
  justify-items: stretch;
  align-items: stretch;

  .colSpan1 {
    grid-column: span 1;
  }

  .colSpan2 {
    grid-column: span 2;
  }

  .colSpan3 {
    grid-column: span 3;
  }

  .colSpan4 {
    grid-column: span 4;
  }

  .colSpan5 {
    grid-column: span 5;
  }

  .colSpan6 {
    grid-column: span 6;
  }

  .colSpan7 {
    grid-column: span 7;
  }

  .colSpan8 {
    grid-column: span 8;
  }

  .colSpan9 {
    grid-column: span 9;
  }

  .colSpan10 {
    grid-column: span 10;
  }

  .colSpan11 {
    grid-column: span 11;
  }

  .colSpan12 {
    grid-column: span 12;
  }

  .center {
    ${centerFlexRow}
  }

  .left {
    ${leftFlexRow}
  }

  > *,
  > * > * {
    box-sizing: border-box;
    width: 100%;
  }
`

export const grid12 = css`
  display: grid;
  grid-template-columns: repeat(12, calc(100% / 12));
  gap: 0 0;
  justify-items: stretch;
  align-items: stretch;

  * {
    box-sizing: border-box;
    width: 100%;
  }
`

export const fitGridWidth = css`
  width: calc(100% - var(--grid-col-gap));
`

export const lightContainer = css`
  border: solid 1px var(--color-medium-grey);
  border-radius: var(--border-radius);
  background-color: var(--color-white);
`

const angle = css`
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  right: 0;
  left: 0;
  height: 100%;
  background: inherit;
`

const bottomAngle = css`
  position: relative;
  z-index: 1;

  &:after {
    ${angle}
  }
`

const topAngle = css`
  position: relative;
  z-index: 1;

  &:before {
    ${angle}
  }
`

export const topAngleLeft = css`
  ${topAngle}

  &:before {
    top: 0;
    transform: skewY(-3deg);
    transform-origin: 0 100%;
  }
`

export const topAngleRight = css`
  ${topAngle}

  &:before {
    top: 0;
    transform: skewY(3deg);
    transform-origin: 100% 0;
  }
`

export const bottomAngleLeft = css`
  ${bottomAngle}

  &:after {
    bottom: 0;
    transform: skewY(-3deg);
    transform-origin: 100%;
  }
`

export const bottomAngleRight = css`
  ${bottomAngle}

  &:after {
    bottom: 0;
    transform: skewY(3deg);
    transform-origin: 0 100%;
  }
`
