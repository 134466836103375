import React from 'react'
import styled from 'styled-components'

import { Line } from '../../components/layout'
import { Select } from '../../components/controls'
import HeaderItem from './headerItem'

import {
  DocumentIcon,
  RedCircleIcon,
  GreenCircleIcon,
  ErrorIcon,
  ConfirmIcon,
} from '../../assets/icons'

import { grid12WithGap, leftFlexRow } from '../../styles/mixins'

const orderStatuses = {
  informationNeeded: {
    label: 'Information Needed',
    icon: RedCircleIcon,
  },
  pendingBuyerAgreement: {
    label: 'Pending Agreement from Buyer',
    icon: ErrorIcon,
  },
  pendingSellerAgreement: {
    label: 'Pending Agreement from Seller',
    icon: ErrorIcon,
  },
  orderPlaced: {
    label: 'Order Placed',
    icon: GreenCircleIcon,
  },
  orderConfirmed: {
    label: 'Order Confirmed',
    icon: ConfirmIcon,
  },
  paymentSent: {
    label: 'Payment Sent',
    icon: ConfirmIcon,
  },
  paymentReceived: {
    label: 'Payment Received',
    icon: ConfirmIcon,
  },
  productSent: {
    label: 'Product Shipped',
    icon: ConfirmIcon,
  },
  productReceived: {
    label: 'Product Received',
    icon: ConfirmIcon,
  },
}

const requestStatusOptions = [
  {
    value: 'open',
    label: 'Open',
    color: 'blue',
  },
  {
    value: 'closed',
    label: 'Closed',
    color: 'red',
  },
  {
    value: 'complete',
    label: 'Complete',
    color: 'green',
  },
]

const Header = ({ className, headerInfo, status, accountType }) => {
  const {
    productName,
    requestId,
    sellerName,
    buyerName,
    company,
    businessUnit,
    requestContact,
    physicalState,
    physicalForm,
    msds,
    tds,
  } = headerInfo

  const {
    orderStatus,
    paymentSent,
    paymentReceived,
    productSent,
    productReceived,
  } = status

  const getRequestStatus = () => {
    let status
    if (accountType === 'seller') {
      if (productSent) {
        status = 'productSent'
      } else if (paymentReceived) {
        status = 'paymentReceived'
      } else if (orderStatus === 'confirmed') {
        status = 'orderConfirmed'
      } else if (orderStatus === 'placed') {
        status = 'orderPlaced'
      } else {
        // check if all information for order has been filled by seller
        status = 'informationNeeded'
      }
    } else {
      if (productReceived) {
        status = 'productReceived'
      } else if (paymentSent) {
        status = 'paymentsent'
      } else if (orderStatus === 'confirmed') {
        status = 'orderConfirmed'
      } else if (orderStatus === 'placed') {
        status = 'orderPlaced'
      } else {
        // check if all information for order has been filled by buyer
        status = 'informationNeeded'
      }
    }
    return status
  }

  const { icon: StatusIcon, label: statusLabel } = orderStatuses[
    getRequestStatus()
  ]

  return (
    <HeaderStyled className={className}>
      <div className='upper'>
        <h2>{productName}</h2>
        <h3>{requestId}</h3>
        <div id='statusSelect'>
          <h3>Status:</h3>
          <Select options={requestStatusOptions} />
        </div>
        <div id='status'>
          <StatusIcon />
          <span>{statusLabel}</span>
        </div>
      </div>
      <Line isThicc={true} />
      <div className='lower'>
        {accountType === 'seller' ? (
          <HeaderItem label='Buyer Name' value={buyerName} />
        ) : (
          <HeaderItem label='Seller Name' value={sellerName} />
        )}
        <HeaderItem label='Company Name' value={company} />
        <HeaderItem label='Business Unit' value={businessUnit} />
        <HeaderItem label='Request Contact' value={requestContact} />
      </div>
      <Line isThicc={false} />
      <div className='lower'>
        <HeaderItem label='Physical State' value={physicalState} />
        <HeaderItem label='Physical Form' value={physicalForm} />
        <HeaderItem
          label='MSDS'
          value={
            <a>
              <DocumentIcon />
            </a>
          }
        />
        <HeaderItem
          label='TDS'
          value={
            <a>
              <DocumentIcon />
            </a>
          }
        />
      </div>
      <Line isThicc={false} />
    </HeaderStyled>
  )
}

const HeaderStyled = styled.div`
  > div {
    ${grid12WithGap}
    align-items: start;
    height: 5rem;
  }

  .lower > div {
    grid-column: span 3;
  }

  .upper {
    height: 4rem;

    > * {
      ${leftFlexRow}
      grid-column: span 3;
      height: 3rem;
      margin: 0;
    }

    #statusSelect {
      > * {
        width: auto;
        margin-bottom: 0;
      }

      h3 {
        margin: 0;
        margin-right: 1rem;
      }

      > div {
        width: 100%;
        margin-right: 1rem;
      }
    }

    #status {
      svg {
        width: auto;
        margin-right: 0.5rem;
      }
    }
  }
`

export default Header
