import React from 'react'
import styled from 'styled-components'

import { centerFlexColumn } from '../../styles/mixins'

const Page = ({ className, children }) => {
  return <PageStyled className={className}>{children}</PageStyled>
}

const PageStyled = styled.div`
  ${centerFlexColumn}
  box-sizing: border-box;
  justify-content: flex-start;
  width: 100%;
  padding: 4rem;
  overflow-x: hidden;
  overflow-y: scroll;

  > * {
    margin-bottom: 2rem;
  }
`

export default Page
