import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import firebase from 'firebase'
import 'firebase/auth'
import { useHistory } from 'react-router-dom'

import { Header, Footer, InputLabeled, Button } from '../../components/controls'

import { lightContainer } from '../../styles/mixins'

const LoginPage = ({ className }) => {
  const [
    signInWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useSignInWithEmailAndPassword(firebase.auth())
  const { register, handleSubmit } = useForm()
  const history = useHistory()

  const loginHandler = (data) => {
    signInWithEmailAndPassword(data.email, data.password).then(() => {
      history.push('/')
    })
  }

  return (
    <LoginPageStyled className={className}>
      <Header />
      <div id='login'>
        <form onSubmit={handleSubmit(loginHandler)}>
          <h2>Login</h2>
          <InputLabeled ref={register} label='Email' name='email' />
          <InputLabeled
            ref={register}
            type='password'
            label='Password'
            name='password'
          />
          <Button text='Login' type='submit' />
        </form>
      </div>
      <Footer />
    </LoginPageStyled>
  )
}

const LoginPageStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;

  #login {
    box-sizing: border-box;
    height: 100vh;
    padding: 20rem;

    form {
      ${lightContainer}
      width: 30rem;
      margin: auto;
      padding: 2rem;

      > *:not(:last-child) {
        margin-bottom: 1rem;
      }

      button {
        margin-left: auto;
      }
    }
  }
`

export default LoginPage
