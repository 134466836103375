import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import { Page, PageTitle, Section, useModal } from '../../components/layout'
import { Button } from '../../components/controls'
import ManualUpload from './manualUpload'
import BatchUpload from './batchUpload'

import { InfoIcon } from '../../assets/icons'
import { centerFlexRow } from '../../styles/mixins'
import UploadTable from './uploadTable'

const templateOptions = [
  {
    value: 'xxx1',
    label: 'xxx1',
  },
  {
    value: 'xxx2',
    label: 'xxx2',
  },
]

const CatalogPage = ({ className }) => {
  const { showModal, hideModal, Modal } = useModal()

  return (
    <CatalogPageStyled className={className}>
      <PageTitle title='Upload New Products and Documents' />
      <Section title='Manual Upload'>
        <ManualUpload />
        <Button className='center' text='Upload' />
      </Section>
      <Section title='Batch Upload'>
        <p>
          Upload a group of product that are from the same functional category.
          Download the correct CSV template corresponding to the appropriate
          functional category.
        </p>
        <div id='templateSelect'>
          <Select options={templateOptions} />
        </div>
        <div id='batchButtons'>
          <Button text='Download Template' theme='yellow' />
          <Button
            text='Upload Instructions'
            icon={InfoIcon}
            theme='red'
            onClick={showModal}
          />
          <Modal title='Upload Instructions' />
        </div>
        <BatchUpload />
        <Button className='center' text='Upload' />
      </Section>
      <Section title='Batch Upload Preview'>
        <UploadTable />
      </Section>
    </CatalogPageStyled>
  )
}

const CatalogPageStyled = styled(Page)`
  button.center {
    margin: 0 auto;
  }

  #templateSelect {
    width: 25%;
  }

  #batchButtons {
    ${centerFlexRow}
    justify-content: space-between;
  }
`

export default CatalogPage
