import React, { useState } from 'react'
import styled from 'styled-components'

import PlainButton from './plainButton'
import FileItem from './fileItem'
import useUploadModal from './uploadModal'
import { isEmpty } from '../../utils'

import { UploadIcon } from '../../assets/icons'

const useUploadButton = () => {
  const [file, setFile] = useState(null)

  const submitHandler = (files) => {
    setFile([files[0]])
  }

  const removeHandler = () => {
    setFile(null)
  }

  const { showUploadModal, UploadModal } = useUploadModal({
    onSubmit: submitHandler,
  })

  const UploadButton = () => {
    return (
      <>
        {isEmpty(file) ? (
          <PlainButton
            icon={UploadIcon}
            onClick={showUploadModal}
            theme='transparent'
          />
        ) : (
          <FileItemStyled fileName={file[0].name} onRemove={removeHandler} />
        )}
        <UploadModal />
      </>
    )
  }

  return { file: file?.[0], UploadButton }
}

const FileItemStyled = styled(FileItem)`
  margin: auto 0;
`

export default useUploadButton
