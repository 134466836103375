import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Textarea = forwardRef(
  (
    {
      onChange,
      autoFocus,
      className,
      name,
      value,
      placeholder,
      isDisabled = false,
    },
    ref
  ) => {
    return (
      <TextareaStyled
        className={className}
        ref={ref}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
      />
    )
  }
)

const TextareaStyled = styled.textarea`
  width: 100%;
  min-height: var(--input-height);
  padding: 0.5rem;
  border-radius: var(--border-radius);
  font-family: inherit;
  font-size: 100%;
  resize: ${(props) => (props.isDisabled ? 'none' : 'vertical')};
`

export default Textarea
