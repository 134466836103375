import React from 'react'
import styled from 'styled-components'

import { BreadCrumb } from '../../components/controls'
import { Page, PageTitle, Section } from '../../components/layout'
import { NewProductForm } from '.'

const NewRequestPage = ({ className }) => {
  const paths = [
    {
      path: '/search',
      name: 'Search',
    },
    {
      path: '/search/customRequest',
      name: 'Custom Request',
    },
  ]
  return (
    <NewRequestPageStyled className={className}>
      <Section>
        <BreadCrumb paths={paths} />
      </Section>
      <Section>
        <PageTitle title='Request a New Product' />
        <NewProductForm />
      </Section>
    </NewRequestPageStyled>
  )
}

const NewRequestPageStyled = styled(Page)``

export default NewRequestPage
