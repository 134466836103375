import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import { getValue, getSelected, styles as selectStyles } from './selectUtils'
import { leftFlexColumn } from '../../styles/mixins'

const SelectLabeled = ({
  onChange,
  blurInputOnSelect = true,
  autoFocus = false,
  className,
  name,
  value,
  defaultValue,
  placeholder,
  label,
  isMulti,
  options = [],
  isDisabled = false,
  styles,
}) => {
  return (
    <SelectLabeledStyled id={name} className={className}>
      <label>{label}</label>
      <Select
        name={name}
        {...getValue(value)}
        selected={getSelected()}
        defaultValue={defaultValue}
        isMulti={isMulti}
        placeholder={placeholder}
        onChange={onChange}
        blurInputOnSelect={blurInputOnSelect}
        autoFocus={autoFocus}
        options={options}
        isDisabled={isDisabled}
        styles={styles || selectStyles}
      />
    </SelectLabeledStyled>
  )
}

const SelectLabeledStyled = styled.div`
  ${leftFlexColumn}
  height: auto;
  font-family: var(--font-primary);

  label {
    margin-bottom: 0.5rem;
  }

  > div {
    width: 100%;
  }
`

export default SelectLabeled
