export const profileData = {
  firstName: 'Winston',
  middleInitial: 'O',
  lastName: 'Liao',
  userEmail: 'winston@valdera.com',
  userPhone: '(650) 123-4567',
  userCompany: 'Valdera',
  userAddress1: '123 Haven St.',
  userAddress2: 'Building 65',
  userCity: 'Palo Alto',
  userState: 'CA',
  userZip: '87893',
  jobFunction: { label: 'Software', value: 'Software' },
  userIndustry: { label: 'Software', value: 'Software' },
  businessUnit: { label: 'Software', value: 'Software' },
  profileImage:
    'https://images.pexels.com/photos/825949/pexels-photo-825949.jpeg',
}

export default profileData
