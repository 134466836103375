import React from 'react'
import styled from 'styled-components'

import { leftFlexColumn } from '../../styles/mixins'

const HeaderItem = ({ className, label, value }) => {
  return (
    <HeaderItemStyled className={className}>
      <h4 class='label'>{label}</h4>
      <span class='value'>{value}</span>
    </HeaderItemStyled>
  )
}

const HeaderItemStyled = styled.div`
  ${leftFlexColumn}
  padding: 1rem 0;

  .label {
    margin-bottom: 0.75rem;
    font-weight: var(--font-weight-bold);
  }
`

export default HeaderItem
