import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'

import useMobileMenu from './mobileMenu'
import { Button, PlainButton } from '../../components/controls'
import {
  clearLinkFormat,
  centerFlexRow,
  bottomAngleLeft,
} from '../../styles/mixins.js'

const headerItems = [
  {
    text: 'Our Platform',
    link: '#request',
  },
  {
    text: 'For Suppliers & Vendors',
    link: '#suppliers',
  },
  {
    text: 'How It Works',
    link: '#how',
  },
  {
    text: 'Contact',
    link: 'mailto:info@valdera.com',
  },
  {
    text: 'Jobs',
    link:
      'https://www.notion.so/valdera/Careers-Valdera-95dc630172904319a9669d8518a0e97a',
    isMobileOnly: true,
  },
  {
    text: 'Login',
    link: '/login',
    isMobileOnly: true,
  },
]

const Header = ({ className, isCollapsed }) => {
  const history = useHistory()

  const isTablet = useMediaQuery({
    query: '(max-width: 1300px)',
  })
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  })

  const hiringClickHandler = () => {
    window.open(
      'https://www.notion.so/valdera/Careers-Valdera-95dc630172904319a9669d8518a0e97a',
      '_blank'
    )
  }

  const loginHandler = () => {
    history.push('/login')
  }

  // const [hasScrolled, setHasScrolled] = useState(window.scrollY - 100 >= 0)
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setHasScrolled(window.scrollY - 100 >= 0)
  //   }
  //   window.addEventListener('scroll', handleScroll, { passive: true })
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, [hasScrolled])

  const getMenu = () => {
    if (isMobile) {
      return <PlainButton className='hamburger' text='☰' onClick={toggleMenu} />
    } else if (isTablet) {
      return (
        <>
          <Button
            text="We're Hiring"
            theme='yellow'
            onClick={hiringClickHandler}
          />
          <Button text='Login' theme='green' onClick={loginHandler} />
          <PlainButton className='hamburger' text='☰' onClick={toggleMenu} />
        </>
      )
    } else {
      return (
        <>
          {headerItems
            .filter((item) => (isMobile ? true : !item.isMobileOnly))
            .map((item, i) => (
              <NavItem {...item} key={i} onClick={hideMenu} />
            ))}
          <Button
            text="We're Hiring"
            theme='yellow'
            onClick={hiringClickHandler}
          />
          <Button text='Login' theme='green' onClick={loginHandler} />
        </>
      )
    }
  }

  const { toggleMenu, hideMenu, MobileMenu } = useMobileMenu()
  return (
    <HeaderStyled className={className} hasScrolled={isCollapsed}>
      <div id='left'>
        <NavItem text='valdera' link='/' hasHover={false} />
      </div>
      <div id='right'>{getMenu()}</div>
      <MobileMenu
        headerItems={headerItems.filter((item) =>
          isMobile ? true : !item.isMobileOnly
        )}
      />
    </HeaderStyled>
  )
}

const HeaderStyled = styled.nav`
  ${centerFlexRow}
  ${bottomAngleLeft}
  position: fixed;
  z-index: 100;
  justify-content: space-between;
  width: 100%;
  height: 8rem;
  transition: all 0.25s ease-in-out;
  background-color: var(--color-white);
  color: var(--color-green);

  :after {
    transition: inherit;
  }

  > div {
    color: inherit;

    > * {
      margin-right: 2rem;
    }
  }

  button {
    height: 2.75rem;
    padding: 0 1.25rem;
    font-size: var(--font-size-small);
  }

  #right {
    ${centerFlexRow}
    z-index: 100;
    transition: inherit;
    font-size: var(--font-size-medium);

    > a {
      margin-right: 4rem;
    }
  }

  #left {
    ${centerFlexRow}
    z-index: 100;
    margin-left: 2rem;
    padding-bottom: 0.6rem;
    transition: inherit;
    font-size: var(--font-size-display);
    font-weight: var(--font-weight-bold);

    > *:first-child {
      margin-left: 2rem;
    }
  }

  @media screen and (max-width: 1200px) {
    .hamburger {
      padding-bottom: 0.25rem;
      color: var(--color-green);
      font-size: var(--font-size-large);
    }
  }

  @media screen and (max-width: 767px) {
    height: 5rem;

    #left {
      margin-left: 0;
      padding-bottom: 0.25rem;
      font-size: var(--font-size-large);
    }

    .hamburger {
      margin-right: 0.75rem;
    }

    .content {
      padding: 4rem 2rem;
    }
  }

  ${(props) => props.hasScrolled && scrolledStyle}
`

const scrolledStyle = css`
  height: 6rem;

  :after {
    transform: skewY(0);
  }

  #left {
    font-size: var(--font-size-large);
  }

  #right {
    font-size: var(--font-size-small);
  }
`

const NavItem = ({ className, text, link, hasHover = true }) => {
  return (
    <NavItemStyled
      className={className}
      href={link}
      activeClassName='active'
      hasHover={hasHover}
    >
      <div>
        <span>{text}</span>
      </div>
    </NavItemStyled>
  )
}

const NavItemStyled = styled.a`
  ${centerFlexRow}
  ${clearLinkFormat}
  height: 100%;
  padding: 1rem 0;

  &:hover {
    box-shadow: ${(props) =>
      props.hasHover ? ' 0px 2px 0px 0px var(--color-green)' : 'none'};
  }
`

export default Header
