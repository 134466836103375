import React from 'react'
import styled from 'styled-components'

import {
  centerFlexColumn,
  centerFlexRow,
  grid12WithGap,
} from '../../styles/mixins'

const ControlMenu = ({ className, options }) => {
  return (
    <ControlMenuStyled className={className}>
      <div id='labels'>
        {options.map((option) => (
          <span
            className={`colSpan${option.width} ${option.labelAlign || 'left'}`}
          >
            {option.props?.label || option.label}
          </span>
        ))}
      </div>
      <div id='controls'>
        {options.map((option) => {
          const { component, width } = option
          return <div className={`colSpan${width}`}>{component}</div>
        })}
      </div>
    </ControlMenuStyled>
  )
}

const ControlMenuStyled = styled.div`
  ${centerFlexColumn}
  width: 100%;
  border: solid 1px var(--color-medium-grey);
  border-radius: var(--border-radius);
  background-color: var(--color-white);

  > div {
    ${grid12WithGap}
    box-sizing: border-box;
    grid-template-rows: auto;
    width: 100%;
    padding: 1rem;

    &#labels {
      border-bottom: solid 1px var(--color-medium-grey);
    }

    > * {
      ${centerFlexRow}
      box-sizing: border-box;
      align-items: flex-start;
    }
  }
`

export default ControlMenu
