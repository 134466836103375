import React from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'

import { Input, PlainButton } from '../../components/controls'
import { CloseIcon } from '../../assets/icons'

import { leftFlexRow } from '../../styles/mixins'

const PricingItem = ({
  className,
  itemName,
  priceName,
  itemValue,
  priceValue,
  control,
  isEditing,
  removeHandler,
}) => {
  return (
    <PricingItemStyled className={className}>
      {isEditing && <PlainButton icon={CloseIcon} onClick={removeHandler} />}
      <Controller
        name={itemName}
        control={control}
        render={({ onChange, value }) => (
          <Input
            className='item'
            type='text'
            onChange={onChange}
            value={value}
            isDisabled={!isEditing}
            isInvisible={!isEditing}
            defaultValue={itemValue}
          />
        )}
      />
      <Controller
        name={priceName}
        control={control}
        render={({ onChange, value }) => (
          <Input
            className='price'
            type='number'
            onChange={onChange}
            value={value}
            isDisabled={!isEditing}
            isInvisible={!isEditing}
            defaultValue={priceValue}
          />
        )}
      />
    </PricingItemStyled>
  )
}

const PricingItemStyled = styled.div`
  ${leftFlexRow}
  width: 100%;
  margin-bottom: 1rem;

  .item {
    flex-grow: 1;
  }

  .price {
    width: 5rem;
    text-align: ${(props) => (!props.isEditing ? 'right' : 'left')};
  }

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

export default PricingItem
