import { useState } from 'react'
import {
  Input,
  InputLabeled,
  Select,
  Creatable,
  CreatableLabeled,
  SelectLabeled,
  Textarea,
  TextareaLabeled,
} from '../components/controls'
import { propertyUnits } from '../data'

export const isEmpty = (item) => {
  if (item === null || item === undefined) return true

  switch (typeof item) {
    case 'string':
      return item === ''
    case 'number':
      return false
    case 'array':
      return item.length() === 0
    case 'object':
      return Object.keys(item).length === 0
    default:
      return false
  }
}

export const getFieldComponent = (field, inputFormat, isLabeled) => {
  const fieldItem = {}
  const unitFieldItem = {}
  const returnItems = []

  const { inputType, isOptional, unit, unitOptions, ...props } = inputFormat[
    field.name
  ]

  if (!isEmpty(unit)) {
    fieldItem.width = field.width - 2
    unitFieldItem.width = 2
    unitFieldItem.props = {
      name: unit,
      label: '',
      options: unitOptions,
    }
    unitFieldItem.component = (props) => <Select {...props} />
    returnItems.unshift(unitFieldItem)
  } else {
    fieldItem.width = field.width
  }

  fieldItem.props = {
    name: field.name,
    isRequired: !isOptional,
    ...props,
  }

  if (!isEmpty(field.value)) fieldItem.props.value = field.value

  switch (inputType) {
    // create date input component
    case 'date':
      fieldItem.component = isLabeled
        ? (props) => <InputLabeled {...props} />
        : (props) => <Input {...props} />
      fieldItem.props = { type: 'date', ...fieldItem.props }
      break
    // create file input component
    case 'file':
    case 'text':
      fieldItem.component = isLabeled
        ? (props) => <InputLabeled {...props} />
        : (props) => <Input {...props} />
      fieldItem.props = { type: 'text', ...fieldItem.props }
      break
    case 'textarea':
      fieldItem.component = isLabeled
        ? (props) => <TextareaLabeled {...props} />
        : (props) => <Textarea {...props} />
      fieldItem.props = { type: 'text', ...fieldItem.props }
      break
    case 'number':
      fieldItem.component = isLabeled
        ? (props) => <InputLabeled {...props} />
        : (props) => <Input {...props} />
      fieldItem.props = { type: 'number', ...fieldItem.props }
      break
    case 'select':
      fieldItem.component = fieldItem.component = isLabeled
        ? (props) => <SelectLabeled {...props} />
        : (props) => <Select {...props} />
      fieldItem.props = { ...fieldItem.props }
      break
    case 'selectCustom':
      fieldItem.component = isLabeled
        ? (props) => <CreatableLabeled {...props} />
        : (props) => <Creatable {...props} />
      fieldItem.props = { ...fieldItem.props }
      break
    case 'multiselect':
      fieldItem.component = isLabeled
        ? (props) => <SelectLabeled {...props} />
        : (props) => <Select {...props} />
      fieldItem.props = { isMulti: true, ...fieldItem.props }
      break
    case 'multiselectCustom':
      fieldItem.component = isLabeled
        ? (props) => <CreatableLabeled {...props} />
        : (props) => <Creatable {...props} />
      fieldItem.props = { isMulti: true, ...fieldItem.props }
      break
    default:
      fieldItem.component = null
  }
  returnItems.unshift(fieldItem)

  return returnItems
}

export const getLabeledFieldComponent = (field, inputFormat) => {
  return getFieldComponent(field, inputFormat, true)
}

export const getLabeledFieldComponents = (fields, inputFormat) => {
  return fields.flatMap((field) => getLabeledFieldComponent(field, inputFormat))
}

export const getFieldComponents = (fields, inputFormat) => {
  return fields.flatMap((field) => getFieldComponent(field, inputFormat, false))
}

export const getFieldComponentPairs = (properties, inputFormat) => {
  const fieldComponentPairs = []
  Object.keys(properties).forEach((fieldName) => {
    const fieldInfo = inputFormat[fieldName]
    const fieldItem = {
      primary: getLabeledFieldComponent(
        {
          name: fieldName,
        },
        { ...inputFormat, ...propertyUnits }
      )[0],
    }

    // use property unit tables
    if (!isEmpty(fieldInfo.unit)) {
      fieldItem.unit = { component: {}, props: {} }
      fieldItem.unit.component = (props) => <Select {...props} />
      fieldItem.unit.props = {
        name: `${fieldName}Unit`,
        label: '',
        options: fieldInfo.unitOptions,
      }
    }
    fieldComponentPairs.push(fieldItem)
  })
  return fieldComponentPairs
}

export const getPropFieldComponents = (productType, inputFormat) => {
  if (isEmpty(productType)) return []

  const fields = Object.keys(inputFormat).filter((key) => {
    if (productType.toLowerCase() === 'chemical')
      return inputFormat[key].isChemProp
    else if (productType.toLowerCase() === 'material')
      return inputFormat[key].isMatProp
    return false
  })

  if (isEmpty(fields)) return []

  const fieldItems = []

  fields.forEach((field) => {
    const fieldItem = {
      prop: {},
      unit: {},
    }
    const { inputType, isOptional, unitOptions, ...props } = inputFormat[field]
    const isRequired = !isOptional

    switch (inputType) {
      case 'select':
        fieldItem.prop.component = (props) => <SelectLabeled {...props} />
        fieldItem.prop.props = {
          name: field,
          isRequired: !isOptional,
          ...props,
        }
        break
      case 'selectCustom':
        fieldItem.prop.component = (props) => <CreatableLabeled {...props} />
        fieldItem.prop.props = {
          name: field,
          isRequired: !isOptional,
          ...props,
        }
        break
      case 'multiselectCustom':
        fieldItem.prop.component = (props) => (
          <SelectLabeled {...props} isMulti={true} />
        )
        fieldItem.prop.props = {
          name: field,
          isRequired: !isOptional,
          ...props,
        }
        break
      case 'text':
        fieldItem.prop.component = (props) => <InputLabeled {...props} />
        fieldItem.prop.props = {
          name: field,
          type: 'text',
          isRequired: !isOptional,
          ...props,
        }
        break
      case 'number':
        fieldItem.prop.component = (props) => <InputLabeled {...props} />
        fieldItem.prop.props = {
          name: field,
          type: 'number',
          isRequired,
          ...props,
        }
        if (!isEmpty(unitOptions)) {
          fieldItem.unit.component = (props) => <Select {...props} />
          fieldItem.unit.props = {
            name: `${field}Unit`,
            label: '',
            options: unitOptions,
          }
        }
        break
      default:
    }
    fieldItems.push(fieldItem)
  })
  return fieldItems
}
