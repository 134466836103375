import React, { useCallback, useState, useMemo } from 'react'
import debounce from 'lodash.debounce'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'

import {
  Button,
  Input,
  Select,
  Checkbox,
  useEdit,
  Tooltip,
} from '../../components/controls/'
import usePage from './usePage'
import Container from './container'
import ConfirmBadge from './confirmBadge'
import { getFieldComponentPairs, isEmpty } from '../../utils'

import { grid12WithGap, leftFlexRow } from '../../styles/mixins'

import { customBuyerRequest as inputFormat } from '../../data'

const sortOptions = [
  { label: 'Default', value: 'default' },
  { label: 'Needs Confirmation', value: 'confirmation' },
]

const Properties = ({
  className,
  isRequestFinalized,
  propVersions,
  accountType,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { dirtyFields },
  } = useForm({ defaultValues: propVersions[propVersions.length - 1] })

  const { isEditing, setIsEditing, EditButton } = useEdit()

  const {
    item: { editedBy, date, ...properties },
    isCurrent,
    pageNum,
    PageControl,
  } = usePage({ items: propVersions, reset, setIsEditing })

  const formData = watch()

  const [sortedProps, setSortedProps] = useState(properties)
  const isDisabled = !isEditing || !isCurrent

  const submitHandler = (data) => console.log(data)

  const [searchValue, setSearchValue] = useState('')

  const searchDebounced = useCallback(
    debounce((searchValue) => {
      setSearchValue(searchValue)
    }, 500),
    []
  )

  const confirmationSort = ([aKey, aVal], [bKey, bVal]) => {
    let aPriority
    let bPriority

    if (accountType === 'seller') {
      aPriority = aVal.isBuyerConfirmed + aVal.isSellerConfirmed * 2
      bPriority = bVal.isBuyerConfirmed + bVal.isSellerConfirmed * 2
    } else {
      aPriority = aVal.isBuyerConfirmed * 2 + aVal.isSellerConfirmed
      bPriority = bVal.isBuyerConfirmed * 2 + bVal.isSellerConfirmed
    }

    if (aPriority < bPriority) return -1
    else if (aPriority > bPriority) return 1
    else return 0
  }

  const sortProps = (data, sortValue) => {
    let sortFunction
    const { editedBy, date, ...propData } = data
    switch (sortValue) {
      case 'confirmation':
        sortFunction = confirmationSort
        break
      case 'default':
      default:
        return propData
    }
    return Object.entries(propData)
      .sort(sortFunction)
      .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {})
  }

  const fieldComponentPairs = useMemo(
    () => getFieldComponentPairs(sortedProps, inputFormat),
    [sortedProps]
  )

  const areSelectArraysEqual = (array0, array1) => {
    if (array0.length !== array1.length) return false

    const valSort = (a, b) => {
      if (a.value < b.value) return -1
      else if (a.value > b.value) return 1
      else return 0
    }

    const array0Sorted = array0.sort(valSort)
    const array1Sorted = array1.sort(valSort)

    for (let i = 0; i < array0.length; i++) {
      if (array0Sorted[i].value !== array1Sorted[i].value) {
        return false
      }
    }
    return true
  }

  const getDiffProps = () => {
    if (pageNum < 2) return new Set()

    const diffProps = new Set()
    const prevProps = propVersions[pageNum - 2]

    Object.entries(properties).forEach(([prop, value]) => {
      const { value: prevValue, unit: prevUnit = null } = prevProps[prop]
      const { value: currValue, unit: currUnit = null } = value

      if (
        Array.isArray(currValue) &&
        !areSelectArraysEqual(prevValue, currValue)
      ) {
        diffProps.add(prop)
      } else if (prevValue.toString() !== currValue.toString()) {
        diffProps.add(prop)
      } else if (
        !isEmpty(prevUnit) &&
        !isEmpty(currUnit) &&
        prevUnit.value !== currUnit.value
      ) {
        diffProps.add(prop)
      }
    })

    return diffProps
  }

  const arePropsDirty = Object.entries(dirtyFields).some(([prop, value]) => {
    const prevValue = properties[prop].value
    const currValue = formData[prop].value
    const prevUnit = properties[prop].unit
    const currUnit = formData[prop].unit

    if (value.unit) {
      return !areSelectArraysEqual(prevUnit, currUnit)
    } else if (value.value) {
      if (Array.isArray(currValue)) {
        return !areSelectArraysEqual(prevValue, currValue)
      } else if (prevValue.toString() === currValue) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  })

  const areConfirmsDirty = Object.entries(dirtyFields).some(([prop, value]) => {
    if (value.isSellerConfirmed || value.isBuyerConfirmed) {
      if (
        formData[prop].isSellerConfirmed ===
          properties[prop].isSellerConfirmed &&
        formData[prop].isBuyerConfirmed === properties[prop].isBuyerConfirmed
      ) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  })

  const onChangeWithClear = (e, name, onChange) => {
    const value = e.target ? e.target.value : e
    const [prop, field] = name.split('.')

    const prevValue = properties[prop][field]
    const confirmName =
      accountType === 'seller'
        ? `${prop}.isBuyerConfirmed`
        : `${prop}.isSellerConfirmed`
    const prevConfirmValue = properties[confirmName]

    if (Array.isArray(prevValue)) {
      if (areSelectArraysEqual([value], prevValue)) {
        setValue(confirmName, prevConfirmValue)
      } else {
        setValue(confirmName, false)
      }
    } else if (value.toString() === prevValue.toString()) {
      setValue(confirmName, prevConfirmValue)
    } else {
      setValue(confirmName, false)
    }
    onChange(e)
  }

  const isBuyerConfirmed = Object.values(formData).every(
    (value) => value.isBuyerConfirmed
  )
  const isSellerConfirmed = Object.values(formData).every(
    (value) => value.isSellerConfirmed
  )

  return (
    <PropertiesStyled
      className={className}
      title='Properties'
      date={date.value}
      pageControl={<PageControl />}
      body={
        <>
          {isCurrent && !isRequestFinalized && (
            <EditButton onReset={() => reset(properties)} />
          )}
          <div className='controls'>
            <Input onChange={(e) => searchDebounced(e.target.value)} />
            <div id='sort'>
              <span>Sort by:</span>
              <Select
                defaultValue={[{ label: 'Default', value: 'default' }]}
                options={sortOptions}
                onChange={(e) => {
                  reset(formData)
                  setSortedProps(sortProps(formData, e.value))
                }}
              />
            </div>
          </div>
          <div className='properties'>
            <div id='headers'>
              <span className='colSpan8'>Properties</span>
              <span data-tip data-for='sellerTooltip' className='colSpan2'>
                Seller
              </span>
              <Tooltip id='sellerTooltip'>Seller</Tooltip>
              <span data-tip data-for='buyerTooltip' className='colSpan2'>
                Buyer
              </span>
              <Tooltip id='buyerTooltip'>Buyer</Tooltip>
            </div>
            {fieldComponentPairs.map((property) => {
              const { primary, unit = {} } = property
              const {
                component: PrimaryComponent,
                props: primaryProps,
              } = primary
              const {
                component: UnitComponent = {},
                props: unitProps = {},
              } = unit

              return (
                <div
                  className={
                    primaryProps.label.toLowerCase().indexOf(searchValue) >= 0
                      ? 'property'
                      : 'hidden'
                  }
                >
                  <Controller
                    name={`${primaryProps.name}.value`}
                    control={control}
                    render={({ onChange, value }) => (
                      <PrimaryComponent
                        className={`${
                          !isEmpty(unit) ? 'colSpan4' : 'colSpan8'
                        } ${
                          getDiffProps().has(primaryProps.name) && 'changed'
                        }`}
                        isDisabled={isDisabled}
                        onChange={(e) =>
                          onChangeWithClear(
                            e,
                            `${primaryProps.name}.unit`,
                            onChange
                          )
                        }
                        value={value}
                        {...primaryProps}
                      />
                    )}
                  />
                  {!isEmpty(unit) && (
                    <Controller
                      name={`${primaryProps.name}.unit`}
                      control={control}
                      render={({ onChange, value }) => (
                        <UnitComponent
                          className='colSpan4'
                          isDisabled={isDisabled}
                          onChange={(e) =>
                            onChangeWithClear(
                              e,
                              `${primaryProps.name}.unit`,
                              onChange
                            )
                          }
                          value={value}
                          {...unitProps}
                        />
                      )}
                    />
                  )}
                  <Controller
                    name={`${primaryProps.name}.isSellerConfirmed`}
                    control={control}
                    render={({ onChange, value }) => (
                      <CheckboxStyled
                        isDisabled={isDisabled || accountType === 'buyer'}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        className='colSpan2'
                      />
                    )}
                  />
                  <Controller
                    name={`${primaryProps.name}.isBuyerConfirmed`}
                    control={control}
                    render={({ onChange, value }) => (
                      <CheckboxStyled
                        isDisabled={isDisabled || accountType === 'seller'}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        className='colSpan2'
                      />
                    )}
                  />
                </div>
              )
            })}
          </div>
        </>
      }
      footer={
        <>
          {(accountType === 'seller'
            ? isBuyerConfirmed
            : isSellerConfirmed) && <ConfirmBadge accountType={accountType} />}
          {!(isBuyerConfirmed && isSellerConfirmed && arePropsDirty) ? (
            <Button
              text='Update Request'
              theme='red'
              onClick={handleSubmit(submitHandler)}
              isDisabled={!(arePropsDirty || areConfirmsDirty)}
            />
          ) : (
            <Button
              text='Finalize Properties'
              theme='green'
              onClick={() => alert('finalized')}
              isDisabled={isRequestFinalized}
            />
          )}
        </>
      }
    ></PropertiesStyled>
  )
}

const PropertiesStyled = styled(Container)`
  .properties {
    > div {
      ${grid12WithGap}
    }

    #headers {
      margin-bottom: 1rem;
      > span:not(:first-child) {
        text-align: center;
      }
    }

    .hidden {
      display: none;
    }

    .property {
      ${grid12WithGap}
      margin-bottom: 1rem;

      .changed label {
        color: var(--color-red);
      }

      > * {
        margin-top: auto;
      }
    }
  }

  .controls {
    ${leftFlexRow}
    margin-bottom: 1rem;

    > *:not(:last-child) {
      margin-right: 1rem;
    }

    #sort {
      ${leftFlexRow}
      justify-content: flex-end;
      margin-left: auto;

      > div {
        width: 10rem;
        margin-left: 0.5rem;
      }
    }
  }
`

const CheckboxStyled = styled(Checkbox)`
  height: var(--input-height);
  margin: 0 auto;
`

export default Properties
