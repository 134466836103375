import React, { useState } from 'react'
import styled from 'styled-components'

import Input from './input'
import Button from './button'

import { centerFlexRow, clearBorder, lightContainer } from '../../styles/mixins'
import { ArrowRightIcon as ArrowIcon } from '../../assets/icons/'

const InputEnter = ({ className, placeholder, onSubmit, icon }) => {
  const [inputValue, setInputValue] = useState('')

  const onChange = (e) => {
    setInputValue(e.target.value)
    if (e.target.value === '') onSubmit?.('')
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') onSubmit(inputValue)
  }

  return (
    <InputEnterStyled>
      <Input
        type='text'
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        value={inputValue}
        onKeyPress={onKeyPress}
        icon={icon}
      />
      <Button
        type='submit'
        onClick={() => onSubmit?.(inputValue)}
        icon={ArrowIcon}
      />
    </InputEnterStyled>
  )
}

const InputEnterStyled = styled.div`
  ${centerFlexRow}
  ${lightContainer}
  height: var(--button-height);
  overflow: hidden;
  border-radius: calc(var(--button-height) / 2);

  > *:first-child {
    ${clearBorder};
    flex-grow: 1;
    height: 100%;
    font-size: var(--font-size-small);

    &::-webkit-search-cancel-button {
      color: var(--color-black);
    }

    &:active,
    :focus {
      ${clearBorder};
    }

    input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: inherit;

      :active,
      :focus {
        border: 0;
      }
    }
  }

  & button {
    ${clearBorder};
    height: 100%;
    padding: 0 1.5rem;
    & span {
      margin: 0;
    }
  }
`

export default InputEnter
