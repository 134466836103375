import React, { useState } from 'react'
import styled from 'styled-components'

import { useInView } from 'react-intersection-observer'

import { Header, Footer } from '../../components/controls'

import HeroSection from './heroSection'
import RequestSection from './requestSection'
import ManageSection from './manageSection'
import InnovateSection from './innovateSection'
import HowSection from './howSection'
import SuppliersSection from './suppliersSection'
import NewsletterSection from './newsletterSection'

import {
  centerFlexColumn,
  leftFlexColumn,
  leftFlexRow,
} from '../../styles/mixins'

const HomePage = ({ className }) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.95,
  })

  return (
    <HomePageStyled className={className} id='home'>
      <Header isCollapsed={!inView} />
      <div id='heroContainer' ref={ref}>
        <HeroSection />
      </div>
      <RequestSection />
      <ManageSection />
      <InnovateSection />
      <HowSection />
      <SuppliersSection />
      <NewsletterSection />
      <Footer />
    </HomePageStyled>
  )
}

const HomePageStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;

  > div:not(#heroContainer),
  #heroContainer > div {
    ${centerFlexColumn}
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 8rem;
  }

  a {
    width: auto;
  }

  img {
    width: 100%;
  }

  button {
    height: 3.25rem;
    padding: 0 1.75rem;
    border-radius: 1.625rem;
    font-size: var(--font-size-small);

    span {
      line-height: var(--font-size-small);
    }
  }

  .content {
    justify-content: space-between;
    width: 100%;
    height: auto;
  }

  .content.column {
    ${leftFlexColumn}

    > * {
      margin-bottom: 4rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      align-self: flex-end;
    }
  }

  .content.row {
    ${leftFlexRow}
    justify-content: space-between;

    .left {
      margin-right: 4rem;
    }

    .right {
      > *:not(:last-child) {
        margin-bottom: 4rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    > div:not(#heroContainer),
    #heroContainer > div {
      padding: 6rem 6rem;
    }

    .content.row {
      ${leftFlexColumn}

      .left {
        order: 2;
        width: 100%;
        margin: 0;
      }

      .right {
        align-self: center;
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    > div:not(#heroContainer),
    #heroContainer > div {
      padding: 6rem 2rem;
    }

    h1 {
      font-size: var(--font-size-large);
    }

    .content.column > * {
      margin-bottom: 2rem;
    }
  }
`

export default HomePage
