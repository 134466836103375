import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { Input } from '.'

import { leftFlexColumn, fitGridWidth } from '../../styles/mixins'

const InputLabeled = forwardRef(
  (
    {
      onChange,
      autoFocus,
      className,
      name,
      value,
      placeholder,
      label,
      type,
      isDisabled = false,
      isInvisible = false,
      icon,
    },
    ref
  ) => {
    return (
      <InputLabeledStyled id={name} className={className}>
        <label>{label}</label>
        <Input
          ref={ref}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autoFocus={autoFocus}
          isDisabled={isDisabled}
          isInvisible={isInvisible}
          icon={icon}
        ></Input>
      </InputLabeledStyled>
    )
  }
)

const InputLabeledStyled = styled.div`
  ${leftFlexColumn}
  height: auto;
  font-family: var(--font-primary);

  label {
    margin-bottom: 0.5rem;
  }

  input {
    ${fitGridWidth}
  }
`

export default InputLabeled
