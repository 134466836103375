import React from 'react'
import styled from 'styled-components'
import {
  Switch,
  Route,
  useLocation,
  BrowserRouter as Router,
} from 'react-router-dom'
import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth'

import { Sidebar } from './components/controls'
import { RegisterPage } from './pages'
import { HomePage } from './pages/home'
import { SearchPage } from './pages/search'
import { CatalogPage } from './pages/catalog'
import { ProfilePage } from './pages/profile'
import { NewRequestPage } from './pages/newRequest'
import { RequestPage } from './pages/request'
import { InboxPage } from './pages/inbox'
import { SettingsPage } from './pages/settings'
import { ProductPage } from './pages/product'
import { ProductRequestPage } from './pages/productRequest'
import { LoginPage } from './pages/login'

import { leftFlexRow, centerFlexColumn } from './styles/mixins.js'

const routes = [
  {
    path: '/register',
    component: RegisterPage,
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/profile',
    component: ProfilePage,
  },
  {
    path: '/search',
    exact: true,
    component: SearchPage,
  },
  {
    path: '/catalog',
    component: CatalogPage,
  },
  {
    path: '/requests',
    exact: true,
    component: InboxPage,
  },
  {
    path: '/requests/:requestId',
    component: RequestPage,
  },
  {
    path: '/search/product/:upid/request',
    component: ProductRequestPage,
  },
  {
    path: '/search/customRequest',
    component: NewRequestPage,
  },
  {
    path: '/settings',
    component: SettingsPage,
  },
  {
    path: '/search/product/:upid',
    component: ProductPage,
  },
]

const App = ({ className }) => {
  const isHome = useLocation().pathname === '/home'

  const firebaseConfig = {
    apiKey: 'AIzaSyA6vYnRV2ubEd0fKLSyGMBDBT1ynoQF5JA',
    authDomain: 'valdera-demo-1-2021.firebaseapp.com',
    projectId: 'valdera-demo-1-2021',
    storageBucket: 'valdera-demo-1-2021.appspot.com',
    messagingSenderId: '782274265038',
    appId: '1:782274265038:web:d5d7ac704a51466464b59f',
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  }

  const [user, loading, error] = useAuthState(firebase.auth())

  return (
    <AppStyled className={className}>
      <Router>
        {user ? (
          <>
            {!isHome && <Sidebar />}
            <main id='main'>
              <Switch>
                {routes.map((route) => (
                  <Route {...route} />
                ))}
              </Switch>
            </main>
          </>
        ) : (
          <Switch>
            {routes
              .filter(
                (route) => route.path === '/home' || route.path === '/login'
              )
              .map((route) => (
                <Route {...route} />
              ))}
          </Switch>
        )}
      </Router>
    </AppStyled>
  )
}

const AppStyled = styled.div`
  ${leftFlexRow}
  align-items: flex-start;
  height: 100vh;
  background-color: var(--color-light-grey);
  overflow-x: auto;
  overflow-y: auto;

  main {
    ${centerFlexColumn}
    justify-content: flex-start;
    width: calc(100% - var(--sidebar-width));
    height: 100%;
  }

  @media screen and (max-width: 1366px) {
    main {
      flex-shrink: 0;
      width: calc(1366px - var(--sidebar-width));
    }
  }
`

export default App
