import React from 'react'
import styled from 'styled-components'
import { NavLink, useHistory } from 'react-router-dom'
import firebase from 'firebase'
import 'firebase/auth'

import {
  centerFlexRow,
  clearLinkFormat,
  leftFlexColumn,
  leftFlexRow,
} from '../../styles/mixins.js'

import {
  HomeIcon,
  IdentityIcon as ProfileIcon,
  NoteAddIcon as CatalogIcon,
  FeedbackIcon as RequestsIcon,
  SettingsIcon,
  SearchIcon,
} from '../../assets/icons/'

const topItems = [
  {
    name: 'Home',
    link: '/home',
    icon: HomeIcon,
  },
  {
    name: 'Profile',
    link: '/profile',
    icon: ProfileIcon,
  },
]

const mainItems = [
  {
    name: 'Product Catalog',
    link: '/catalog',
    icon: CatalogIcon,
  },
  {
    name: 'Product Search',
    link: '/search',
    icon: SearchIcon,
  },
  {
    name: 'Requests & Quotes',
    link: '/requests',
    icon: RequestsIcon,
  },
]

const bottomItems = [
  {
    name: 'Settings',
    link: '/settings',
    icon: SettingsIcon,
  },
]

const SidebarUnstyled = ({ className }) => {
  const history = useHistory()
  const signOutHandler = () => {
    firebase.auth().signOut()
    history.push('/home')
  }

  return (
    <nav className={className}>
      <div id='top'>
        <h3 id='title'>Valdera</h3>
        {topItems.map((item, i) => (
          <NavItem {...item} key={i}></NavItem>
        ))}
      </div>
      <div id='main'>
        {mainItems.map((item, i) => (
          <NavItem {...item} key={i}></NavItem>
        ))}
      </div>
      <div id='bottom'>
        <NavItem name='Logout' icon={ProfileIcon} onClick={signOutHandler} />
        {bottomItems.map((item, i) => (
          <NavItem {...item} key={i}></NavItem>
        ))}
      </div>
    </nav>
  )
}

const Sidebar = styled(SidebarUnstyled)`
  ${leftFlexColumn}
  box-sizing: border-box;
  flex-shrink: 0;
  width: var(--sidebar-width);
  height: 100vh;
  padding: 2rem 0;
  overflow: hidden;
  background-color: var(--color-green);
  color: var(--color-white);

  & > div {
    width: 100%;
  }

  & #title {
    width: 100%;
    margin-top: 0;
    padding-left: 4.5rem;
  }

  & #main {
    flex-grow: 1;
    margin-top: 5rem;
  }

  @media screen and (max-height: 720px) {
    height: 720px;
  }
`

const NavItemUnstyled = ({ className, name, link, icon: Icon, onClick }) => {
  return link ? (
    <NavLink className={className} to={link} activeClassName='active'>
      <div>
        <div>
          <Icon />
        </div>
        <span>{name}</span>
      </div>
    </NavLink>
  ) : (
    <a className={className} onClick={onClick} activeClassName='active'>
      <div>
        <div>
          <Icon />
        </div>
        <span>{name}</span>
      </div>
    </a>
  )
}

const NavItem = styled(NavItemUnstyled)`
  ${leftFlexRow}
  ${clearLinkFormat}
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem 2rem;
  color: white;
  cursor: pointer;

  & :hover {
    span {
      text-shadow: 0 0 4px rgba(255, 255, 255, 0.35);
    }

    svg {
      filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.35));
    }
  }

  & :last-child {
    margin-bottom: 0;
  }

  > div {
    ${leftFlexRow}

    > div {
      ${centerFlexRow}
      width: 1.5rem;
      height: auto;
      margin-right: 1rem;

      svg path {
        fill: var(--color-white);
      }
    }
  }

  &.active {
    background-color: var(--color-light-grey);
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.25);

    span {
      color: var(--color-green);
    }

    > div > div > svg path {
      fill: var(--color-green);
    }
  }
`

export default Sidebar
