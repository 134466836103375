import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import {
  clearLinkFormat,
  centerFlexRow,
  leftFlexColumn,
} from '../../styles/mixins.js'

const rightItems = [
  {
    text: 'Home',
    link: '/home',
  },
  {
    text: 'Contact Us',
    link: 'mailto:info@valdera.com',
  },
  {
    text: 'Careers',
    link:
      'https://www.notion.so/valdera/Careers-Valdera-95dc630172904319a9669d8518a0e97a',
    newTab: true,
  },
]

const Footer = ({ className }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 768px)',
  })

  return (
    <FooterStyled className={className}>
      <div id='left-footer'>
        <h1>Valdera</h1>
        <p>San Francisco, CA 94107</p>
        <p>650-714-2601</p>
      </div>
      {!isMobile && (
        <div id='right-footer'>
          {rightItems.map((item, i) => (
            <NavItem {...item} key={i}></NavItem>
          ))}
        </div>
      )}
    </FooterStyled>
  )
}

const FooterStyled = styled.footer`
  ${centerFlexRow}
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 16rem;
  padding: 4rem;
  background-image: radial-gradient(
    circle farthest-corner at 25% 80%,
    #10484b 0%,
    #000101 100%
  );
  color: var(--color-white);

  > div {
    ${centerFlexRow}
    color: inherit;

    > * {
      margin-right: 2rem;
    }

    > *:first-child {
      margin-left: 2rem;
    }
  }

  #left {
    font-size: var(--font-size-mediumer);
    font-weight: var(--font-weight-medium);
  }

  #left-footer {
    ${leftFlexColumn}

    h1 {
      margin: 0;
      margin-bottom: 1rem;
      padding: 0;
    }

    a,
    p {
      margin: 0;
      padding: 0;
    }
  }

  #right-footer {
    a {
      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    height: 12rem;
    padding: 2rem;
  }
`

const NavItemUnstyled = ({ className, text, link, newTab = false }) => {
  return (
    <a className={className} href={link} target={newTab ? '_blank' : ''}>
      <div>
        <span>{text}</span>
      </div>
    </a>
  )
}

const NavItem = styled(NavItemUnstyled)`
  ${centerFlexRow}
  ${clearLinkFormat}

  height: 100%;
  margin-right: 4rem;
  padding: 0 1rem;
  font-size: var(--font-size-medium);
`

export default Footer
