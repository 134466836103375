import React from 'react'
import styled from 'styled-components'

import { centerFlexRow } from '../../styles/mixins.js'
import { isEmpty } from '../../utils'

const Button = ({
  className,
  type = 'button',
  text,
  theme,
  icon,
  hasIconLeft = true,
  onClick,
  isDisabled = false,
}) => {
  const Icon = icon
  return (
    <ButtonStyled
      type={type}
      className={className}
      onClick={onClick}
      hasIconLeft={hasIconLeft}
      theme={theme}
      icon={icon}
      hasText={!isEmpty(text)}
      disabled={isDisabled}
      isDisabled={isDisabled}
    >
      {hasIconLeft && Icon && <Icon />}
      {text && <span>{text}</span>}
      {!hasIconLeft && Icon && <Icon />}
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button`
  ${centerFlexRow}
  box-sizing: border-box;
  height: var(--button-height);
  padding: ${(props) => (props.hasText ? `0 1.5rem` : `0`)};
  border: none;
  border-radius: calc(var(--button-height) / 2);
  background-color: ${(props) =>
    props.isDisabled ? getBgColor('grey') : getBgColor(props.theme)};
  color: ${(props) => getColor(props.theme)};
  font-family: var(--font-primary);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  cursor: pointer;

  & :active,
  :focus {
    border: none;
    outline: none;
  }

  svg path {
    fill: ${(props) => getColor(props.theme)};
    fill-opacity: 1;
  }

  span {
    ${(props) =>
      props.hasIconLeft ? `margin-left: 0.5rem;` : `margin-right: 0.5rem;`};
    ${(props) => !props.icon && `margin: 0;`}
    line-height: var(--font-size-small);
  }
`

const getColor = (theme) => {
  switch (theme) {
    case 'yellow':
    case 'transparent':
    case 'grey':
      return `var(--color-black)`
    case 'green':
    case 'red':
    case 'black':
      return `var(--color-white)`
    default:
      return `var(--color-white)`
  }
}

const getBgColor = (theme) => {
  switch (theme) {
    case 'yellow':
      return `var(--color-yellow)`
    case 'green':
      return `var(--color-green)`
    case 'transparent':
      return `transparent`
    case 'red':
      return `var(--color-red)`
    case 'grey':
      return `var(--color-medium-grey)`
    case 'black':
      return `var(--color-black)`
    default:
      return `var(--color-green)`
  }
}

export default Button
