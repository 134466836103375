import React from 'react'
import styled from 'styled-components'

import { ProductForm } from '../../components/controls'
import { supplierUpload } from '../../data'

const fields = [
  [
    { name: 'productType', width: 3 },
    { name: 'productDescription', width: 3 },
  ],
  [
    { name: 'physicalState', width: 4 },
    { name: 'physicalForm', width: 4 },
    { name: 'iupacName', width: 4 },
  ],
  [
    { name: 'additionalProductInformation', width: 3 },
    { name: 'functionalCategory', width: 3 },
    { name: 'shelfLife', width: 3 },
    { name: 'chemicalClass', width: 3 },
  ],
  [
    { name: 'industries', width: 3 },
    { name: 'useCases', width: 3 },
    { name: 'storageAndHandling', width: 6 },
  ],
  [
    { name: 'industries', width: 6 },
    { name: 'externalCertifications', width: 6 },
  ],
  [
    { name: 'regulatoryInformation', width: 6 },
    { name: 'formulationRequirements', width: 6 },
  ],
]

const logisticsFields = []

const UploadForm = ({ className, onSubmit }) => {
  return (
    <UploadFormStyled
      className={className}
      fields={fields}
      logisticsFields={logisticsFields}
      inputFormat={supplierUpload}
      onSubmit={onSubmit}
    />
  )
}

const UploadFormStyled = styled(ProductForm)``

export default UploadForm
