import React from 'react'
import styled from 'styled-components'

import {
  Input,
  Button,
  Textarea,
  useUploadModal,
} from '../../components/controls'
import Message from './message'
import Container from './container'
import { centerFlexRow, centerFlexColumn } from '../../styles/mixins'

const Messages = ({ className, messages, username = 'Sruti' }) => {
  const { showUploadModal, UploadModal } = useUploadModal()

  const submitHandler = () => {}
  const uploadHandler = () => {}
  return (
    <MessagesStyled
      className={className}
      title='Messages'
      body={messages.map(({ username: messageUsername, ...props }) => (
        <Message {...props} isUser={username === messageUsername} />
      ))}
      footer={
        <MessageInputStyled>
          {' '}
          <Textarea />
          <div className='buttons'>
            <Button text='Send' onClick={submitHandler} theme='green' />
            <Button text='File' onClick={showUploadModal} theme='red' />
          </div>
          <UploadModal onSubmit={uploadHandler} />
        </MessageInputStyled>
      }
    />
  )
}

const MessageInputStyled = styled.form`
  ${centerFlexRow}
  align-items: flex-start;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  .buttons {
    ${centerFlexColumn}
    align-items: stretch;

    > * {
      margin-bottom: 0.5rem;
    }
  }
`

const MessagesStyled = styled(Container)``

export default Messages
