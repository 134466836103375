import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

const TooltipUnstyled = ({ className, id, children }) => {
  return (
    <ReactTooltip
      className={className}
      id={id}
      effect='solid'
      scrollHide={true}
    >
      {children}
    </ReactTooltip>
  )
}

const Tooltip = styled(TooltipUnstyled)`
  width: auto !important;
`

export default Tooltip
