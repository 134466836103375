import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { Page, Section } from '../../components/layout'
import Header from './header'
import Confirmation from './confirmation'
import Properties from './properties'
import Pricing from './pricing'
import Payment from './payment'
import Messages from './messages'

import { request1 } from '../../data'

import { grid12WithGap } from '../../styles/mixins'

const RequestPage = ({ className }) => {
  const { requestId } = useParams()
  const {
    headerInformation,
    status,
    messages,
    properties,
    payment,
    pricing,
  } = request1
  const accountType = 'seller'
  const username = 'sruti'
  const { orderStatus, requestStatus } = status
  const isRequestFinalized =
    orderStatus === 'complete' ||
    requestStatus === 'closed' ||
    requestStatus === 'complete'
  return (
    <RequestPageStyled className={className}>
      <Section>
        <Header
          headerInfo={headerInformation}
          status={status}
          accountType={accountType}
          isRequestfinalied={isRequestFinalized}
        />
        <div id='body'>
          {isRequestFinalized && (
            <Confirmation className='colSpan12' accountType={accountType} />
          )}
          <div id='left' className='colSpan6'>
            <PropertiesStyled
              date='today'
              accountType={accountType}
              propVersions={properties}
              isRequestFinalized={isRequestFinalized}
            />
          </div>
          <div id='right' className='colSpan6'>
            <MessagesStyled
              messages={messages}
              username={username}
              isRequestFinalized={isRequestFinalized}
            />
            <PricingStyled
              date='today'
              accountType={accountType}
              pricing={pricing}
              isRequestFinalized={isRequestFinalized}
            />
            <PaymentStyled
              date='today'
              accountType={accountType}
              payment={payment}
              isRequestFinalized={isRequestFinalized}
            />
          </div>
        </div>
      </Section>
    </RequestPageStyled>
  )
}

const RequestPageStyled = styled(Page)`
  #body {
    ${grid12WithGap}

    #right > div {
      margin-bottom: var(--grid-row-gap);
    }
  }
`

const PropertiesStyled = styled(Properties)`
  height: calc(95rem + 2 * var(--grid-row-gap));
`

const PricingStyled = styled(Pricing)`
  height: 30rem;
  overflow: auto;
  resize: vertical;
`

const PaymentStyled = styled(Payment)`
  height: 25rem;
`

const MessagesStyled = styled(Messages)`
  height: 40rem;
  overflow: auto;
  resize: vertical;
`

export default RequestPage
