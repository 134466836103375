import { default as collagen } from './collagen.json'
import { default as sodiumStearate } from './sodiumStearate.json'
import { default as titaniumDioxide } from './titaniumDioxide.json'

export { default as productDataFormat } from './product'
export { default as uploadDataFormat } from './upload'
export { default as profileData } from './profile'

export { default as buyerRequest } from './buyerRequest.json'
export { default as customBuyerRequest } from './customBuyerRequest.json'
export { default as supplierUpload } from './supplierUpload.json'
export { default as profile } from './profile.json'
export { default as propertyUnits } from './propertyUnits.json'
export { default as inputMaster } from './inputMaster.json'

export { default as titaniumDioxideRequest } from './titaniumDioxideRequest.json'
export { default as productUpload } from './productUpload.json'
export { default as request1 } from './request1.json'

const productData = [collagen, sodiumStearate, titaniumDioxide]
export { productData }
