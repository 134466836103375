import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

import { centerFlexColumn, centerFlexRow } from '../../styles/mixins'

const Container = ({
  className,
  body,
  footer,
  title,
  date,
  pageControl: PageControl,
}) => {
  return (
    <ContainerStyled className={className}>
      <div className='containerHeader'>
        <h4 className='title'>{title}</h4>
        {date && (
          <span className='date'>{format(new Date(date), 'PP | p')}</span>
        )}
        {PageControl}
      </div>
      <div className='containerBody'>{body}</div>
      <div className='containerFooter'>{footer}</div>
    </ContainerStyled>
  )
}

const ContainerStyled = styled.div`
  ${centerFlexColumn}
  border: solid 1px var(--color-medium-grey);
  background-color: var(--color-white);

  > div {
    box-sizing: border-box;
    width: 100%;
  }

  .containerHeader {
    ${centerFlexRow}
    flex: 0 0 3rem;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
    border-bottom: solid 1px var(--color-medium-grey);

    h4 {
      margin: 0;
    }
  }

  .date {
    color: var(--color-dark-grey);
  }

  .containerBody {
    flex-grow: 1;
    padding: 2rem;
    overflow-y: auto;
  }

  .containerFooter {
    ${centerFlexRow}
    justify-content: flex-end;
    padding: 1rem;

    > button:not(:last-child) {
      margin-right: 1rem;
    }
  }
`

export default Container
