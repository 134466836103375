import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import ArrowLink from './arrowLink'

import {
  centerFlexRow,
  leftFlexRow,
  leftFlexColumn,
  centerFlexColumn,
  bottomAngleRight,
} from '../../styles/mixins'

import {
  AccountIcon,
  ClipboardIcon,
  ContractIcon,
  RouteIcon,
  SampleIcon,
  TruckIcon,
} from '../../assets/icons/home'

const steps = [
  {
    image: AccountIcon,
    title: 'Create an account',
    description: 'Provide contact info and register. Click here to sign up.',
  },
  {
    image: ClipboardIcon,
    title: 'Request unique formulations',
    description:
      'Make unique requests  with custom properties that cater to your exact use case.',
  },
  {
    image: RouteIcon,
    title: 'Get routed to the best suppliers',
    description:
      'With a single click, get automatically routed to all suppliers that match your needs.',
  },
  {
    image: SampleIcon,
    title: 'Negotiate quotes and recieve samples',
    description:
      'Manage your requests, negotiations, and quotes, all in one place.',
  },
  {
    image: ContractIcon,
    title: 'Confirm order!',
    description:
      'Track the status of your order, and convert into a long-term contract if needed.',
  },
]

const HowSection = ({ className }) => {
  return (
    <HowSectionStyled id='how' className={className}>
      {' '}
      <div className='content column'>
        <h1>How It Works</h1>
        <div className='steps'>
          {steps.map((step, i) => (
            <Fade bottom>
              <Step index={i} isLast={i === steps.length - 1} {...step} />
            </Fade>
          ))}
        </div>
        <ArrowLink text='Get Started' link='mailto:demo@valdera.com' />
      </div>
    </HowSectionStyled>
  )
}

const HowSectionStyled = styled.div`
  ${bottomAngleRight}
  ${centerFlexColumn}
    padding-right: 16rem;
  padding-left: 16rem;
  background-color: var(--color-white);

  path {
    fill: var(--color-black);
  }

  @media screen and (max-width: 1200px) {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  @media screen and (max-width: 767px) {
    padding-right: 2rem;
    padding-left: 2rem;

    .content {
      margin-bottom: -1rem;
    }
  }
`

const Step = ({ className, index, image, title, description, isLast }) => (
  <StepStyled className={className}>
    <div className='left-step'>
      <div className='step-number'>Step {index + 1}</div>
      <img src={image} />
      <div className='line-container'>
        {!isLast && <div className='dotted-line' />}
      </div>
    </div>
    <div className='right-step'>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </StepStyled>
)

const StepStyled = styled.div`
  ${leftFlexRow}
  margin-bottom: 6rem;

  .left-step {
    ${centerFlexColumn}
    flex-shrink: 0;
    width: 10rem;
    margin-right: 4rem;

    img {
      height: 4rem;
      object-fit: contain;
    }

    .step-number {
      ${centerFlexRow}
      height: 2rem;
      margin-bottom: 1.5rem;
      padding: 0 2rem;
      border-radius: 1rem;
      background-color: var(--color-black);
      color: var(--color-white);
    }

    .line-container {
      width: 0;
      height: 0;
    }

    .dotted-line {
      position: absolute;
      box-sizing: border-box;
      width: 0;
      height: 5rem;
      margin-top: 0.5rem;
      border-left: dashed 3px var(--color-medium-grey);
    }
  }

  .right-step p {
    font-size: var(--font-size-medium);
  }

  @media screen and (max-width: 767px) {
    ${leftFlexColumn}
    margin-bottom: 3rem;

    .text {
      order: 2;
      margin: 0;
    }

    .left-step {
      align-self: center;
      width: 10rem;
      margin: 2rem 0;

      .step-number {
        margin-bottom: 2rem;
      }

      .line-container {
        display: none;
      }
    }

    .right-step p {
      font-size: var(--font-size-small);
    }
  }
`

export default HowSection
