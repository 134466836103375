import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { getFieldComponent, isEmpty } from '../../utils'
import { supplierUpload, propertyUnits } from '../../data'

const InputCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateCell,
}) => {
  const [showInput, setShowInput] = useState(false)
  const [value, setValue] = useState(initialValue)

  const { component: Component, props } = useMemo(
    () =>
      getFieldComponent(
        { name: id },
        { ...supplierUpload, ...propertyUnits }
      )[0],
    [id]
  )

  const multiselectStyles = {
    valueContainer: (provided) => ({
      ...provided,
    }),
  }

  const clickHandler = () => {
    setShowInput(true)
  }

  const changeHandler = (e) => {
    let value
    if (isEmpty(e)) {
      value = null
    } else if (!isEmpty(e.value)) {
      // for select components
      value = e
    } else if (!isEmpty(e.target)) {
      // for input components
      value = e.target.value
    }
    setValue(value)
  }

  const blurHandler = () => {
    setShowInput(false)
    updateCell(index, id, value)
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const parseValue = () => {
    if (isEmpty(value)) return ''
    if (Array.isArray(value))
      return value.map((value) => value.label).join(', ')
    if (!isEmpty(value?.label)) return value.label
    return value
  }

  return (
    <InputCellStyled onBlur={blurHandler} onClick={clickHandler}>
      {showInput ? (
        <Component
          value={value}
          onChange={changeHandler}
          {...props}
          styles={multiselectStyles}
          autoFocus={true}
          blurInputOnSelect={false}
        />
      ) : (
        <div className='displayValue'>{parseValue()}</div>
      )}
    </InputCellStyled>
  )
}

const InputCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .displayValue {
    width: 100%;
    height: auto;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export default InputCell
