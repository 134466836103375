import React from 'react'
import styled from 'styled-components'

import { ArrowRightIcon } from '../../assets/icons'
import { centerFlexRow, clearLinkFormat } from '../../styles/mixins'

const ArrowLink = ({ className, text, link }) => {
  return (
    <ArrowLinkStyled className={className} href={link}>
      {text}
      <ArrowRightIcon />
    </ArrowLinkStyled>
  )
}

const ArrowLinkStyled = styled.a`
  ${centerFlexRow}
  ${clearLinkFormat}
  display: inline-flex;
  align-self: flex-end;
  margin-left: auto;
  padding-bottom: 1rem;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  cursor: pointer;

  svg {
    margin-left: 1rem;
  }

  &:hover {
    box-shadow: 0px 2px 0px 0px var(--color-black);
  }
`

export default ArrowLink
