import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'

import { inputMaster } from '../../data'

import { isEmpty } from '../../utils'

import { lightContainer } from '../../styles/mixins'

const propertyFields = [
  {
    display: 'Property',
    field: 'label',
  },
  {
    display: 'Value',
    field: 'range',
  },
  {
    display: 'Unit',
    field: 'unit',
  },
  {
    display: 'Additional Info',
    field: 'additionalInfo',
  },
]

const parseProperties = (properties) => {
  const parsedProperties = []
  Object.entries(properties)
    .filter(
      ([key, item]) => inputMaster[key].isChemProp || inputMaster[key].isMatProp
    )
    .forEach(([key, item]) => {
      const property = {
        label: inputMaster[key].label,
        range: !isEmpty(item.value)
          ? item.value
          : `${item.minValue} - ${item.maxValue}`,
        unit: item.unit,
      }
      parsedProperties.push(property)
    })
  return parsedProperties
}

const Properties = ({ className, properties }) => {
  const data = useMemo(() => parseProperties(properties), [])

  const columns = useMemo(
    () =>
      propertyFields.map((item) => ({
        Header: item.display,
        accessor: item.field,
      })),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <PropertiesStyled className={className} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </PropertiesStyled>
  )
}

const PropertiesStyled = styled.table`
  ${lightContainer}
  width: 100%;
  border-spacing: 0;
  border-radius: var(--border-radius);

  tr {
    border-bottom: solid 1px var(--color-medium-grey);
  }

  th,
  td {
    padding: 0.5rem 1rem;
    border-bottom: solid 1px var(--color-medium-grey);
  }

  tr:last-child td {
    border: 0;
  }
`

export default Properties
