import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { ChevronDownIcon } from '../../assets/icons'

const BreadCrumb = ({ className, paths }) => {
  return (
    <BreadCrumbStyled className={className}>
      {paths.map(({ path, name }, i) => {
        if (i !== paths.length - 1) {
          return (
            <>
              <Link to={path}>
                <span>{name}</span>
              </Link>
              <ChevronDownIcon />
            </>
          )
        } else {
          return <span>{name}</span>
        }
      })}
    </BreadCrumbStyled>
  )
}

const BreadCrumbStyled = styled.div`
  a {
    color: var(--color-black);
    text-decoration: none;
  }

  > *:not(:last-child) {
    margin-right: 1rem;
  }

  svg {
    margin-bottom: 0.1rem;
    transform: rotate(270deg) scaleX(0.65);

    path {
      fill: var(--color-dark-grey);
    }
  }
`

export default BreadCrumb
