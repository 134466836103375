import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTable, useFilters } from 'react-table'
import { useHistory } from 'react-router-dom'

import { Page, PageTitle, Section } from '../../components/layout'
import { InputEnter, Button } from '../../components/controls'
import Filter from './filter'
import DisplayCell from './displayCell'
import { isEmpty } from '../../utils'

import { leftFlexColumn } from '../../styles/mixins'
import { SearchIcon } from '../../assets/icons'

import { inputMaster as inputFields, productData } from '../../data'

const properties = productData.reduce((propsList, product) => {
  Object.entries(product).forEach(([name, props]) => {
    if (
      name !== 'productDescription' &&
      name !== 'scientificDescription' &&
      (!isEmpty(props.value) || !isEmpty(props.minValue)) &&
      !propsList.includes(name)
    ) {
      propsList.push(name)
    }
  })
  return propsList
}, [])

const optionsFilter = (rows, accessor, options) => {
  const property = accessor[0]
  if (
    isEmpty(options) ||
    ![
      'useCases',
      'industries',
      'physicalForm',
      'functionalCategory',
      'chemicalClass',
    ].includes(property)
  )
    return rows
  return rows.filter((row) => {
    let returnValue = false
    const value = row.values[property].value
    if (Array.isArray(value)) {
      returnValue = value.some((val) => options.includes(val))
    } else {
      returnValue = options.includes(value)
    }
    return returnValue
  })
}

const searchFilter = (rows, accessor, searchValue) => {
  if (isEmpty(searchValue)) return rows
  const property = accessor[0]
  return rows.filter((row) =>
    row.values[property].value.toLowerCase().includes(searchValue.toLowerCase())
  )
}

const defaultColumn = {
  Cell: DisplayCell,
}

const SearchPage = ({ className }) => {
  const data = useMemo(() => productData, [])
  const history = useHistory()

  const onSearch = (value) => {
    setFilter('productName', value)
  }

  const columns = useMemo(
    () =>
      properties.map((property) => {
        return {
          Header: inputFields[property].label,
          accessor: property,
          filter: property === 'productName' ? searchFilter : optionsFilter,
        }
      }),
    []
  )

  const clickHandler = (upid) => {
    history.push(`/search/product/${upid}`)
  }

  const customRequestHandler = () => {
    history.push('/search/customRequest')
  }

  // const getWidth = (column) => ({
  //   style: {
  //     minWidth: `200`,
  //   },
  // })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable({ columns, data, defaultColumn }, useFilters)

  return (
    <SearchPageStyled className={className}>
      <PageTitle title='Search Products' />
      <Section>
        <SearchInput onSubmit={(e) => onSearch(e)} icon={SearchIcon} />
        <Filter setFilter={setFilter} products={productData} />
        <div id='tableContainer'>
          <table className={className} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => clickHandler(row.original.upid.value)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div id='custom'>
          <h3>Need to make a special request?</h3>
          <Button
            text='Custom Request'
            theme='red'
            onClick={customRequestHandler}
          />
        </div>
      </Section>
    </SearchPageStyled>
  )
}

const SearchPageStyled = styled(Page)`
  #tableContainer {
    overflow: auto;
    border: solid 1px var(--color-medium-grey);
    border-radius: var(--border-radius);
  }

  table {
    width: 100%;
    padding-bottom: 1rem;
    table-layout: fixed;
    border-spacing: 0;
    background-color: var(--color-white);

    th,
    td {
      width: 12rem;
      margin: 0 1rem;
      padding: 1rem;
    }

    th {
      padding: 1rem;
    }

    td {
      border: 0;
      border-right: solid 1rem var(--color-white);
      background-color: var(--color-light-grey);

      &:first-child {
        border-left: solid 1rem var(--color-white);
      }
    }

    tr {
      cursor: pointer;
    }
  }

  #table {
    overflow-x: auto;
  }

  #custom {
    ${leftFlexColumn}
    justify-content: space-between;
    margin-top: 4rem;
  }
`

const SearchInput = styled(InputEnter)``

export default SearchPage
