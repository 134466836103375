import React, { useState } from 'react'
import PlainButton from './plainButton'

import { EditIcon, ResetIcon } from '../../assets/icons'

export const useEdit = () => {
  const [isEditing, setIsEditing] = useState(false)

  const EditButton = ({ onEdit, onReset }) => {
    const editHandler = () => {
      onEdit?.()
      setIsEditing(true)
    }

    const resetHandler = () => {
      onReset?.()
      setIsEditing(false)
    }

    return (
      <PlainButton
        text={isEditing ? 'Reset' : 'Edit'}
        icon={isEditing ? ResetIcon : EditIcon}
        hasIconLeft={false}
        onClick={isEditing ? resetHandler : editHandler}
      />
    )
  }

  return { isEditing, setIsEditing, EditButton }
}

export default useEdit
