import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { leftFlexRow } from '../../styles/mixins'

const Checkbox = forwardRef(
  ({ className, name, value, label, onChange, checked, isDisabled }, ref) => {
    return (
      <CheckboxStyled className={className}>
        <input
          type='checkbox'
          ref={ref}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={isDisabled}
        />
        {label && <label>{label}</label>}
      </CheckboxStyled>
    )
  }
)

const CheckboxStyled = styled.div`
  ${leftFlexRow}
  padding: 0.5em;

  input {
    margin-top: 2px;
  }

  label {
    margin-left: 0.5em;
  }
`

export default Checkbox
