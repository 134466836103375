import React from 'react'
import styled from 'styled-components'

import { Page, PageTitle, Section, Line } from '../../components/layout'
import { Button } from '../../components/controls'

import { grid12WithGap, leftFlexRow } from '../../styles/mixins'
import { isEmpty } from '../../utils'

import { profile, profileData } from '../../data'

const aboutFields = [
  [
    { name: 'firstName', width: 3 },
    { name: 'middleInitial', width: 1 },
    { name: 'lastName', width: 3 },
  ],
  [
    { name: 'userEmail', width: 4 },
    { name: 'userPhone', width: 3 },
  ],
]

const roleFields = [
  [
    { name: 'jobFunction', width: 3 },
    { name: 'helpWith', width: 3 },
    { name: 'userAdditionalInformation', width: 6 },
  ],
]

const companyFields = [
  [
    { name: 'userCompany', width: 4 },
    { name: 'userIndustry', width: 3 },
  ],
  [{ name: 'userAddress1', width: 6 }],
  [{ name: 'userAddress2', width: 6 }],
  [
    { name: 'userCity', width: 3 },
    { name: 'userState', width: 1 },
    { name: 'userZip', width: 3 },
  ],
]

const fieldGroups = [
  {
    title: 'Contact Information',
    fields: aboutFields,
  },
  {
    title: 'Your Role',
    fields: roleFields,
  },
  {
    title: 'Company',
    fields: companyFields,
  },
]

const ProfilePage = ({ className }) => {
  const {
    profileImage,
    firstName,
    middleInitial,
    lastName,
    userCompany,
    jobFunction: { value: jobFunction },
  } = profileData

  return (
    <ProfilePageStyled className={className}>
      <PageTitle title='Public Profile' />
      <Section numRows={2}>
        <Line isThicc={true} />
        <div id='display'>
          <img src={profileImage} alt='profile' />
          <div>
            <p>
              <strong>{`${firstName} ${
                !isEmpty(middleInitial) && `${middleInitial}. `
              }${lastName}`}</strong>
            </p>
            <p>{userCompany}</p>
            <p>{jobFunction}</p>
          </div>
        </div>
        <Line isThicc={false} />
        {fieldGroups.map((fieldGroup) => (
          <div className='fieldContainer'>
            <h3>{fieldGroup.title}</h3>
            {fieldGroup.fields.map((fieldRow) => (
              <div className='fieldRow'>
                {fieldRow.map((field) => {
                  let value
                  if (profileData[field.name] === undefined) {
                    value = ''
                  } else if (profileData[field.name].value !== undefined) {
                    value = profileData[field.name].value
                  } else {
                    value = profileData[field.name]
                  }
                  return (
                    <div className={`colSpan${field.width} fieldItem`}>
                      <div>
                        <strong>{profile[field.name].label}</strong>
                      </div>
                      <div>{value}</div>
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
        ))}
        <Line isThicc={false} />
        <Button text='Edit Profile' theme='red' />
      </Section>
    </ProfilePageStyled>
  )
}

const ProfilePageStyled = styled(Page)`
  .fieldContainer {
    margin-bottom: 2rem;
  }

  .fieldRow {
    ${grid12WithGap}
    margin-bottom: 2rem;
  }

  .fieldItem {
    div:first-child {
      margin-bottom: 1rem;
    }
  }

  #display {
    ${leftFlexRow}

    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 50%;
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    }

    div {
      margin-left: 2rem;
    }
  }

  button {
    margin-left: auto;
  }
`

export default ProfilePage
