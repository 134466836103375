import React, { useState } from 'react'
import styled from 'styled-components'

import { Page, PageTitle, Line, Section } from '../../components/layout'
import { InputEnter } from '../../components/controls'
import FilterButton from './filterButton'
import MessagePreview from './messagePreview'

import { SearchIcon } from '../../assets/icons'
import { centerFlexRow } from '../../styles/mixins'
import { isEmpty } from '../../utils'

const filters = [
  {
    label: 'General',
    messageText: 'General Message',
    value: 'general',
    color: 'yellow',
  },
  {
    label: 'Open Requests',
    messageText: 'Open Request',
    value: 'openRequest',
    color: 'blue',
  },
  {
    label: 'Resolved Requests',
    messageText: 'Resolved Request',
    value: 'resolvedRequest',
    color: 'green',
  },
  {
    label: 'Contract Discussions',
    messageText: 'Contract Discussion',
    value: 'contractDiscussion',
    color: 'red',
  },
]

const allMessages = [
  {
    requestId: '1',
    type: 'openRequest',
    username: 'Robert',
    company: 'Valdera',
    message: 'Hello!',
    date: 'June 2nd, 2021',
  },
  {
    requestId: '2',
    type: 'resolvedRequest',
    username: 'Erika',
    company: 'Valdera',
    message: 'Hi!',
    date: 'June 22nd, 2021',
  },
]

const getTypeProps = (type) => {
  return filters.find((filter) => filter.value === type)
}

const InboxPage = ({ className }) => {
  const [filterValue, setFilterValue] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const getMessages = () => {
    if (isEmpty(searchValue) && isEmpty(filterValue)) return allMessages

    let filteredMessages = allMessages
    if (!isEmpty(searchValue)) {
      filteredMessages = filteredMessages.filter((message) => {
        let returnValue = false
        Object.keys(message).forEach((key) => {
          if (message[key].includes(searchValue)) returnValue = true
        })
        return returnValue
      })
    }
    if (!isEmpty(filterValue)) {
      filteredMessages = filteredMessages.filter(
        (message) => message.type === filterValue
      )
    }
    return filteredMessages
  }

  return (
    <InboxPageStyled className={className}>
      <PageTitle title='Inbox' />
      <Line isThicc={true} />
      <Section>
        <div id='filterContainer'>
          {filters.map(({ value, label, color }) => (
            <FilterButton
              text={label}
              color={color}
              isActive={filterValue === value}
              onClick={() => setFilterValue(value === filterValue ? '' : value)}
            />
          ))}
        </div>
        <p>Messages</p>
        <Line isThicc={false} />
        <div id='search'>
          <InputEnter
            type='search'
            icon={SearchIcon}
            onSubmit={setSearchValue}
          />
        </div>
        <div id='messages'>
          {getMessages().map(({ type, ...props }) => {
            const { color, messageText } = getTypeProps(type)
            return (
              <MessagePreview {...props} color={color} type={messageText} />
            )
          })}
        </div>
        <Line isThicc={false} />
      </Section>
    </InboxPageStyled>
  )
}

const InboxPageStyled = styled(Page)`
  #filterContainer {
    ${centerFlexRow}
    justify-content: space-between;
    margin-top: 2rem;
  }

  #search {
    width: 50%;
  }

  #messages {
    > div {
      margin-bottom: 0.5rem;
    }
  }
`

export default InboxPage
