import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Page, Section, Line, PageTitle } from '../../components/layout'
import { Button, PlainButton, useEdit } from '../../components/controls'

import { grid12WithGap, centerFlexRow } from '../../styles/mixins'
import { getLabeledFieldComponents, isEmpty } from '../../utils'
import { EditIcon, CloseIcon } from '../../assets/icons'

import { profileData, profile } from '../../data'

const schema = yup.object().shape({
  firstName: yup.string().required(),
  middleInitial: yup.string().max(1),
  lastName: yup.string().required(),
})

const aboutFields = [
  [
    { name: 'firstName', width: 3 },
    { name: 'middleInitial', width: 1 },
    { name: 'lastName', width: 3 },
  ],
  [
    { name: 'userEmail', width: 4 },
    { name: 'userPhone', width: 3 },
  ],
]

const roleFields = [
  [
    { name: 'jobFunction', width: 3 },
    { name: 'helpWith', width: 3 },
    { name: 'userAdditionalInformation', width: 6 },
  ],
]

const companyFields = [
  [
    { name: 'userCompany', width: 4 },
    { name: 'userIndustry', width: 3 },
  ],
  [{ name: 'userAddress1', width: 6 }],
  [{ name: 'userAddress2', width: 6 }],
  [
    { name: 'userCity', width: 3 },
    { name: 'userState', width: 1 },
    { name: 'userZip', width: 3 },
  ],
]

const profileFieldGroups = [
  {
    title: 'Contact Information',
    fields: aboutFields,
  },
  {
    title: 'Your Role',
    fields: roleFields,
  },
  {
    title: 'Company',
    fields: companyFields,
  },
]

const accountFields = [
  { name: 'accountEmail', width: 3 },
  { name: 'accountPhone', width: 3 },
]

const SettingsPage = ({ className }) => {
  const {
    handleSubmit: handleSubmitAccount,
    control: controlAccount,
  } = useForm()
  const {
    handleSubmit: handleSubmitProfile,
    control: controlProfile,
  } = useForm({ defaultValues: profileData })
  const {
    isEditing: isEditingProfile,
    EditButton: EditProfileButton,
  } = useEdit()
  const {
    isEditing: isEditingAccount,
    EditButton: EditAccountButton,
  } = useEdit()
  const onSubmit = (data) => console.log(data)

  return (
    <SettingsPageStyled className={className}>
      <PageTitle title='Account Settings' />
      <Section>
        <Line isThicc={true} />
        <div className='editHeader'>
          <h2>Account Info</h2>
          <div id='edit'>
            <EditAccountButton />
          </div>
        </div>
        <form onSubmit={handleSubmitAccount(onSubmit)}>
          <div className='fieldRow'>
            {getLabeledFieldComponents(accountFields, profile).map(
              (fieldItem) => {
                const Component = fieldItem.component
                const props = fieldItem.props
                const isDisabled = !isEditingAccount
                return (
                  <div className={`colSpan${fieldItem.width}`}>
                    <Controller
                      name={props.name}
                      control={controlAccount}
                      render={({ onChange, value, ...rest }) => {
                        return (
                          <Component
                            value={value}
                            onChange={onChange}
                            isDisabled={isDisabled}
                            {...props}
                          />
                        )
                      }}
                    />
                  </div>
                )
              }
            )}
          </div>
          <Button text='Change Password' theme='red' />
          <Line isThicc={false} />
          <Button type='submit' text='Update' theme='green' />
        </form>
      </Section>
      <Section>
        <div className='editHeader'>
          <h2>Business Profile</h2>
          <div id='edit'>
            <EditProfileButton />
          </div>
        </div>

        <form onSubmit={handleSubmitProfile(onSubmit)}>
          {profileFieldGroups.map((fieldGroup) => (
            <div className='fieldContainer'>
              <h3>{fieldGroup.title}</h3>
              {fieldGroup.fields.map((fieldRow) => (
                <div className='fieldRow'>
                  {getLabeledFieldComponents(fieldRow, profile).map(
                    (fieldItem) => {
                      const Component = fieldItem.component
                      const props = fieldItem.props
                      const isDisabled = !isEditingProfile
                      return (
                        <div className={`colSpan${fieldItem.width}`}>
                          <Controller
                            name={props.name}
                            control={controlProfile}
                            render={({ onChange, value, ...rest }) => {
                              return (
                                <Component
                                  value={value}
                                  onChange={onChange}
                                  isDisabled={isDisabled}
                                  {...props}
                                />
                              )
                            }}
                          />
                        </div>
                      )
                    }
                  )}
                </div>
              ))}
            </div>
          ))}
          <Line isThicc={false} />
          <p>
            <strong>Note:&nbsp;</strong>This information is associated with your
            acounts. Modifying this information will not affect your public
            profile settings. Click here to edit your public profile.
          </p>
          <Button type='submit' text='Update' theme='green' />
        </form>
      </Section>
    </SettingsPageStyled>
  )
}

const SettingsPageStyled = styled(Page)`
  .fieldContainer {
    margin-bottom: 2rem;
  }

  .fieldRow {
    ${grid12WithGap}
    margin-bottom: 1rem;
  }

  #edit {
    margin-bottom: 0;
  }

  .editHeader {
    ${centerFlexRow}
    justify-content: space-between;
    margin-bottom: 0;
  }

  button[type='submit'] {
    margin-left: auto;
  }
`

export default SettingsPage
