import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { grid12WithGap, leftFlexRow } from '../../styles/mixins'

const MessagePreview = ({
  className,
  requestId,
  type,
  username,
  company,
  message,
  date,
  color,
}) => {
  const history = useHistory()

  const clickHandler = () => {
    history.push(`/requests/${requestId}`)
  }

  return (
    <MessagePreviewStyled
      className={className}
      color={color}
      onClick={clickHandler}
    >
      <div class='summary colSpan6'>
        <div class='typeColor' />
        <span>
          {type} from {username} of {company}
        </span>
      </div>
      <span class='message colSpan4'>{message}</span>
      <span class='date colSpan2'>{date}</span>
    </MessagePreviewStyled>
  )
}

const MessagePreviewStyled = styled.div`
  ${grid12WithGap}
  padding: 1rem;
  background-color: var(--color-white);
  cursor: pointer;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .summary {
    ${leftFlexRow}

    .typeColor {
      width: 10px;
      height: 10px;
      margin: 0 0.5rem 0 0.25rem;
      background-color: ${(props) => `var(--color-${props.color})`};
    }
  }

  .date {
    text-align: right;
  }
`

export default MessagePreview
