import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, FileItem } from './'
import { Line, useModal } from '../layout'

import { centerFlexRow } from '../../styles/mixins'

const useUploadModal = ({ isMulti = false, onSubmit = () => {} } = {}) => {
  const {
    showModal: showUploadModal,
    hideModal: hideUploadModal,
    Modal,
  } = useModal()

  const [files, setFiles] = useState([])

  const submitHandler = () => {
    onSubmit?.(files)
    hideUploadModal()
  }

  const addFile = (e) => {
    const fileList = e.target.files
    if (isMulti) {
      const newFiles = []
      for (let i = 0; i < fileList.length; i++) {
        newFiles.push(fileList[i])
      }
      setFiles(files.concat(newFiles))
    } else {
      setFiles([fileList[0]])
    }
  }

  const removeFile = (i) => {
    const newFiles = [...files]
    newFiles.splice(i, 1)
    setFiles(newFiles)
  }

  const UploadModal = ({ className }) => {
    return (
      <Modal
        className={className}
        title='Upload'
        body={
          <UploadFormStyled>
            <div id='upload'>
              <label for='fileList'>
                {isMulti ? 'Select Files' : 'Select File'}
              </label>
              <input
                name='fileList'
                type='file'
                onChange={addFile}
                id='fileList'
                multiple={isMulti}
              />
            </div>
            <div id='files'>
              {files.map((file, i) => (
                <FileItem
                  key={i}
                  fileName={file.name}
                  onRemove={() => removeFile(i)}
                />
              ))}
            </div>
          </UploadFormStyled>
        }
        footer={
          <Button type='submit' text='Confirm Upload' onClick={submitHandler} />
        }
      />
    )
  }

  return { showUploadModal, hideUploadModal, UploadModal }
}

const UploadFormStyled = styled.form`
  width: 100%;

  #upload {
    ${centerFlexRow}
    display: inline-flex;
    box-sizing: border-box;
    width: auto;
    height: var(--button-height);
    padding: 0 1.5rem;
    border: none;
    border-radius: calc(var(--button-height) / 2);
    background-color: var(--color-green);
    color: var(--color-white);
    font-family: var(--font-primary);
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    label {
      z-index: 1;

      cursor: pointer;
    }

    input {
      z-index: 0;
      width: 0;
      height: 0;
    }
  }

  #files {
    height: 15rem;
    overflow-y: auto;
  }
`

export default useUploadModal
