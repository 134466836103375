import React from 'react'
import styled from 'styled-components'

import ArrowLink from './arrowLink'
import { workflowImage } from '../../assets/images'

const ManageSection = ({ className }) => {
  return (
    <ManageSectionStyled id='manage' className={className}>
      <div className='content column'>
        <h2>Manage your end-to-end sourcing workflow in our platform</h2>
        <img src={workflowImage} />
        <ArrowLink text='Request a demo' link='mailto:demo@valdera.com' />
      </div>
    </ManageSectionStyled>
  )
}

const ManageSectionStyled = styled.div`
  height: auto;
  padding-top: 12rem;
  background-image: radial-gradient(
    circle farthest-corner at 25% 80%,
    #10484b 0%,
    #000101 100%
  );
  color: var(--color-white);

  path {
    fill: var(--color-white);
  }

  h2 {
    font-weight: var(--font-weight-normal);
  }

  a:hover {
    box-shadow: 0px 2px 0px 0px var(--color-white);
  }

  img {
    height: 30rem;
    object-fit: contain;
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: var(--font-size-medium);
    }

    img {
      height: 15rem;
    }
  }
`

export default ManageSection
