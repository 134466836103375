import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import { ControlMenu, useUploadButton } from '../../components/controls'

const uploadOptions = [
  {
    value: 'xxxx1',
    label: 'xxxx1',
  },
  {
    value: 'xxxx2',
    label: 'xxxx2',
  },
]

const funcGroupOptions = [
  {
    value: 'xxxx1',
    label: 'xxxx1',
  },
  {
    value: 'xxxx2',
    label: 'xxxx2',
  },
]

const uploadSelect = <Select options={uploadOptions} />
const funcGroupSelect = <Select options={funcGroupOptions} />

const BatchUpload = ({ className }) => {
  const { file: csv, UploadButton: CsvUploadButton } = useUploadButton()
  const { file: msds, UploadButton: MsdsUploadButton } = useUploadButton()
  const { file: tds, UploadButton: TdsUploadButton } = useUploadButton()

  const options = [
    {
      label: 'Product Name',
      labelAlign: 'left',
      component: uploadSelect,
      width: 4,
    },
    {
      label: 'CSV Upload',
      labelAlign: 'center',
      component: <CsvUploadButton />,
      width: 2,
    },
    {
      label: 'MSDS Upload',
      labelAlign: 'center',
      component: <MsdsUploadButton />,
      width: 2,
    },
    {
      label: 'TDS Upload',
      labelAlign: 'center',
      component: <TdsUploadButton />,
      width: 2,
    },
    {
      label: 'Functional Group',
      labelAlign: 'left',
      component: funcGroupSelect,
      width: 2,
    },
  ]

  return <BatchUploadStyled className={className} options={options} />
}

const BatchUploadStyled = styled(ControlMenu)``

export default BatchUpload
