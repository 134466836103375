import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { lightContainer } from '../../styles/mixins'

import { isEmpty } from '../../utils'

const Input = forwardRef(
  (
    {
      className,
      autoFocus,
      type = 'text',
      name,
      value,
      placeholder,
      defaultValue,
      onChange,
      onBlur,
      onClick,
      onKeyPress,
      isDisabled = false,
      isInvisible = false,
      icon: Icon,
    },
    ref
  ) => {
    return (
      <InputStyled
        className={className}
        isDisabled={isDisabled}
        isInvisible={isInvisible}
        hasIcon={!isEmpty(Icon)}
      >
        {Icon && <Icon />}
        <input
          ref={ref}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          onKeyPress={onKeyPress}
          autoFocus={autoFocus}
          disabled={isDisabled}
        />
      </InputStyled>
    )
  }
)

const InputStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: calc(var(--input-height) + 2px);

  svg {
    position: absolute;
    top: calc(100% / 2 - 10px);
    left: 1rem;

    path {
      fill: var(--color-dark-grey);
    }
  }

  input {
    ${lightContainer}
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 0.5rem;
    ${(props) => props.hasIcon && 'padding-left: 3rem;'}

    background-color: ${(props) => getBgColor(props)};

    font-family: var(--font-primary);

    ${(props) =>
      props.isInvisible &&
      `background-color: transparent; border-color: transparent;`}

    & :active, :focus {
      border: solid 1px var(--color-dark-grey);
    }
  }
`

const getBgColor = ({ isDisabled = false, isInvisible = false }) => {
  if (isDisabled) return 'var(--color-light-grey)'
  if (isInvisible) return 'transparent'
  else return 'var(--color-white)'
}

const getBorderColor = ({ isDisabled = false, isInvisible = false }) => {
  if (isDisabled) return 'var(--color-medium-grey)'
  if (isInvisible) return 'transparent'
  else return 'var(--color-medium-grey)'
}

export default Input
