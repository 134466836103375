import { isEmpty } from '../../utils'

export const getSelected = (value, options) => {
  if (isEmpty(value) || isEmpty(options)) return null
  const arrayValue = Array.isArray(value) ? value : [value]
  const selected = options.filter((option) =>
    arrayValue?.some((val) => option.value === val.value)
  )
  return selected.length === 0 ? null : selected
}

export const getValue = (value) =>
  value !== undefined ? { value: Array.isArray(value) ? value : [value] } : {}

export const styles = {
  control: (provided) => ({
    ...provided,
    height: 'calc(var(--input-height) + 2px)',
    borderRadius: 'var(--border-radius)',
    border: 'solid 1px var(--color-medium-grey)',
  }),
}
