import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: {},
    middleware: [...getDefaultMiddleware()],
    preloadedState,
    enhancers: [],
  })

  return store
}
