import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Checkbox } from '../../components/controls'
import { ChevronDownIcon as ExpandIcon } from '../../assets/icons'
import { leftFlexRow, grid12WithGap } from '../../styles/mixins'
import { isEmpty } from '../../utils'

const filterOptions = [
  {
    name: 'useCases',
    label: 'Use Cases',
  },
  {
    name: 'industries',
    label: 'Industries',
  },
  {
    name: 'physicalState',
    label: 'Physical State',
  },
  {
    name: 'physicalForm',
    label: 'Physical Form',
  },
  {
    name: 'functionalCategory',
    label: 'Functional Category',
  },
]

const Filter = ({ className, setFilter, products }) => {
  const [activeOptionGroup, setActiveOptionGroup] = useState(null)
  const [activeOptions, setActiveOptions] = useState([])

  const [useCases, setUseCases] = useState([])
  const [industries, setIndustries] = useState([])
  const [physicalStates, setPhysicalStates] = useState([])
  const [physicalForms, setPhysicalForms] = useState([])
  const [functionalCategories, setFunctionalCategories] = useState([])

  const onChange = (accessor, newValue) => {
    let setValue = () => {}
    let values = []

    switch (accessor) {
      case 'useCases':
        setValue = setUseCases
        values = useCases
        break
      case 'industries':
        setValue = setIndustries
        values = industries
        break
      case 'physicalState':
        setValue = setPhysicalStates
        values = physicalStates
        break
      case 'physicalForm':
        setValue = setPhysicalForms
        values = physicalForms
        break
      case 'functionalCategory':
        setValue = setFunctionalCategories
        values = functionalCategories
        break
      default:
        return
    }

    const index = values.indexOf(newValue)

    if (index >= 0) values.splice(index, 1)
    else values.push(newValue)
    setValue(values)
    setFilter(accessor, values)
  }

  const getOptions = () => {
    let options = []
    products.forEach((product) => {
      const productOptions = product[activeOptionGroup]?.value
      if (!isEmpty(productOptions)) {
        if (
          typeof productOptions === 'string' &&
          !options.includes(productOptions)
        )
          options.push(productOptions)
        else if (Array.isArray(productOptions))
          options = [
            ...options,
            ...productOptions.filter((option) => !options.includes(option)),
          ]
      }
    })
    return options
  }

  const getActiveOptions = (optionGroup) => {
    switch (optionGroup) {
      case 'useCases':
        return useCases
      case 'industries':
        return industries
      case 'physicalState':
        return physicalStates
      case 'physicalForm':
        return physicalForms
      case 'functionalCategory':
        return functionalCategories
      default:
        return []
    }
  }

  return (
    <FilterStyled className={className}>
      <div id='optionGroups'>
        {filterOptions.map((option) => (
          <FilterButton
            active={option.name === activeOptionGroup}
            key={option.name}
            hasIconLeft={false}
            icon={ExpandIcon}
            text={option.label}
            theme='transparent'
            onClick={() => {
              setActiveOptionGroup(
                option.name === activeOptionGroup ? '' : option.name
              )
              setActiveOptions(getActiveOptions(option.name))
            }}
          />
        ))}
      </div>
      {!isEmpty(activeOptionGroup) && (
        <div id='dropdown'>
          <div id='options'>
            {getOptions().map((option) => (
              <Checkbox
                key={option}
                name={option}
                value={option}
                label={option}
                checked={activeOptions.includes(option)}
                onChange={() => onChange(activeOptionGroup, option)}
              />
            ))}
          </div>
        </div>
      )}
    </FilterStyled>
  )
}

const FilterStyled = styled.div`
  width: 100%;
  border: solid 1px var(--color-medium-grey);
  border-radius: var(--border-radius);
  background-color: var(--color-white);

  #optionGroups {
    ${leftFlexRow}
    padding: 0.5em;

    > * {
      flex-grow: 1;
    }

    button {
    }
  }

  #dropdown {
    padding: 1em;
    padding-top: 0;
    border-radius: var(--border-radius);
    background-color: var(--color-white);

    > div:first-child {
      ${grid12WithGap}
      row-gap: 0;

      > * {
        grid-column: span 3;

        > input {
          width: auto;
        }
      }
    }
  }
`

const FilterButton = styled(Button)`
  span {
    font-size: var(--font-size-small);
  }

  svg {
    margin-top: 0.25rem;
    ${(props) => props.active && `transform: rotateX(180deg);`}
  }
`

export default Filter
