import React from 'react'
import styled from 'styled-components'

import { DoneIcon } from '../../assets/icons'

import { centerFlexRow } from '../../styles/mixins'

const ConfirmBadge = ({ className, accountType }) => {
  return (
    <ConfirmBadgeStyled className={className}>
      <DoneIcon />
      <span>
        Confirmed by{' '}
        {accountType.charAt(0).toUpperCase() + accountType.substring(1)}
      </span>
    </ConfirmBadgeStyled>
  )
}

const ConfirmBadgeStyled = styled.div`
  ${centerFlexRow}
  margin-right: auto;

  svg {
    margin-right: 0.5rem;
  }

  svg,
  svg path {
    stroke: var(--color-green);
    fill: var(--color-green);
  }
`

export default ConfirmBadge
