import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { Button } from '../controls'
import Line from './line'
import { CloseIcon } from '../../assets/icons'

import {
  centerFlexColumn,
  leftFlexColumn,
  centerFlexRow,
  leftFlexRow,
  lightContainer,
} from '../../styles/mixins'

const root = document.getElementById('root')

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  const showModal = () => setIsVisible(true)
  const hideModal = () => setIsVisible(false)

  const Modal = ({ className, title, body, footer, onHide, styles }) => {
    const hideModal = () => {
      onHide?.()
      setIsVisible(false)
    }

    return createPortal(
      <>
        {isVisible ? (
          <>
            <ModalStyled
              className={className}
              onClick={hideModal}
              styles={styles}
            >
              <div id='modal' onClick={(event) => event.stopPropagation()}>
                {title && (
                  <div id='header'>
                    <div>
                      <h3>{title}</h3>
                      <Button
                        onClick={hideModal}
                        theme='transparent'
                        icon={CloseIcon}
                      />
                    </div>

                    <Line isThicc={true} />
                  </div>
                )}
                <div id='body'>{body}</div>
                {footer && <div id='footer'>{footer}</div>}
              </div>
            </ModalStyled>
          </>
        ) : null}
      </>,
      root
    )
  }

  return { showModal, hideModal, Modal }
}

const ModalStyled = styled.div`
  ${centerFlexColumn}
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  #modal {
    ${lightContainer}
    min-width: 30rem;
    max-height: 60rem;
    margin: auto;
    overflow: auto;
    background-color: var(--color-white);

    > * {
      padding: 0.5em 1em;
    }

    ${(props) => props.styles}
  }

  #footer {
    ${centerFlexRow}
    justify-content: space-between;
    padding: 1rem 1.5rem;
    border-top: solid 1px var(--color-medium-grey);
  }

  #header {
    ${leftFlexColumn}
    padding: 1.5rem;
    padding-bottom: 0;

    > div {
      ${leftFlexRow}
      justify-content: space-between;
      width: 100%;
      padding-bottom: 0.75rem;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  #body {
    ${leftFlexColumn}
    padding: 1.5rem;
    overflow-y: auto;
  }
`

export default useModal
