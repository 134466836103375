import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { leftFlexColumn } from '../../styles/mixins'

const TextareaLabeled = forwardRef(
  (
    {
      onChange,
      autoFocus,
      className,
      name,
      value,
      placeholder,
      label,
      isDisabled = false,
    },
    ref
  ) => {
    return (
      <TextareaLabeledStyled
        id={name}
        className={className}
        isDisabled={isDisabled}
      >
        <label>{label}</label>
        <textarea
          ref={ref}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autoFocus={autoFocus}
          disabled={isDisabled}
        />
      </TextareaLabeledStyled>
    )
  }
)

const TextareaLabeledStyled = styled.div`
  ${leftFlexColumn}

  textarea {
    box-sizing: border-box;
    width: 100%;
    min-height: var(--input-height);
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: var(--border-radius);
    font-family: inherit;
    font-size: 100%;
    resize: ${(props) => (props.isDisabled ? 'none' : 'vertical')};
  }
`

export default TextareaLabeled
