import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'

import InputCell from './inputCell'
import {
  supplierUpload as inputFormat,
  propertyUnits,
  productUpload as productData,
} from '../../data'
import { isEmpty } from '../../utils'

const defaultColumn = {
  Cell: InputCell,
}

const UploadTable = ({ className }) => {
  const [data, setData] = useState(productData)

  const columns = useMemo(() => {
    let fields = []
    Object.entries(inputFormat).forEach(([key, item]) => {
      fields.push({
        Header: item.label,
        accessor: key,
      })
      if (!isEmpty(item.unit)) {
        fields.push({
          Header: propertyUnits[item.unit].label,
          accessor: item.unit,
        })
      }
    })
    return fields
  }, [])

  const updateCell = (rowIndex, columnId, value) => {
    setData((originalData) =>
      originalData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...originalData[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data, defaultColumn, updateCell })

  return (
    <UploadTableStyled className={className}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </UploadTableStyled>
  )
}

const UploadTableStyled = styled.div`
  max-height: 40em;
  overflow: auto;
  border: solid 1px var(--color-medium-grey);
  border-radius: var(--border-radius);

  table {
    padding-bottom: 1rem;
    table-layout: fixed;
    border-spacing: 0;
    background-color: var(--color-white);
    box-shadow: 0 0 0 1px var(--color-medium-grey);

    th,
    td {
      min-width: 200px;
      max-width: 200px;
      height: 4rem;
      padding: 0 1rem;
    }

    th {
      background-color: var(--color-white);
    }

    td {
      border: 0;
      border-right: solid 1rem var(--color-white);
      background-color: var(--color-light-grey);
    }

    tr {
      height: 4rem;
    }

    thead tr:first-child {
      position: sticky;
      z-index: 99;
      top: 0;
      left: 0;

      th {
        position: sticky;
        z-index: 99;
        top: 0;
        //box-shadow: 0 -1px 0 0 var(--color-medium-grey);

        &:first-child {
          z-index: 100;
          left: 0;
          //box-shadow: -1px -1px 0 1px var(--color-medium-grey);
        }
      }
    }

    tbody tr td:first-child {
      position: sticky;
      z-index: 99;
      left: 0;
      border-left: solid 1rem var(--color-white);
      //box-shadow: -1px 0 0 0 var(--color-medium-grey);
    }
  }
`

export default UploadTable
