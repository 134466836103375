import React, { useRef } from 'react'
import styled from 'styled-components'
import { Player } from '@lottiefiles/react-lottie-player'
import Fade from 'react-reveal/Fade'
import { InView } from 'react-intersection-observer'

import { Button } from '../../components/controls'

import { HeroLottie } from '../../assets/lottie'
import { ArrowRightIcon } from '../../assets/icons'

import { centerFlexRow } from '../../styles/mixins'

const HeroSection = ({ className, ref }) => {
  const playerRef = useRef(null)

  const changeHandler = (inView) => {
    if (inView) playerRef.current.play()
  }

  return (
    <HeroSectionStyled className={className}>
      <div className='content row' ref={ref}>
        <div className='left'>
          <h1>
            Your one-stop solution for specialized chemicals and raw materials
          </h1>
          <p>
            Find the raw inputs you need to build the next generation of
            products, whether it’s for R&D or full-scale production.
          </p>
          <div className='buttonContainer'>
            <Button
              text='Request a demo'
              hasIconLeft={false}
              icon={ArrowRightIcon}
              theme='yellow'
              onClick={() => {
                window.open('mailto:demo@valdera.com')
              }}
            />
            <Button
              text='Create an account'
              hasIconLeft={false}
              icon={ArrowRightIcon}
              theme='grey'
              onClick={() => {
                window.open('mailto:info@valdera.com')
              }}
            />
          </div>
        </div>
        <div className='right'>
          <InView threshold={0.5} onChange={changeHandler}>
            {({ inView, ref, entry }) => (
              <Fade clear>
                <div ref={ref}>
                  <Player
                    keepLastFrame
                    ref={playerRef}
                    src={HeroLottie}
                    onEvent={(event) => {
                      if (event === 'load') {
                        playerRef.current.setPlayerSpeed(1)
                      } else if (event === 'play') {
                        if (!inView) playerRef.current.pause()
                      }
                    }}
                  />
                </div>
              </Fade>
            )}
          </InView>
        </div>
      </div>
    </HeroSectionStyled>
  )
}

const HeroSectionStyled = styled.div`
  margin-top: 8rem;
  background-color: var(--color-black);
  background-image: radial-gradient(
    circle farthest-corner at 25% 80%,
    #10484b 0%,
    #000101 100%
  );
  color: var(--color-white);

  .content.row {
    ${centerFlexRow}
    margin: 2rem 0;

    .left {
      width: 50%;
      margin-right: 5rem;

      > h1 {
        margin-bottom: 2rem;
      }

      > p {
        margin-bottom: 4rem;
      }

      button {
        display: inline-flex;
        margin-right: 2rem;
      }
    }

    .right {
      flex-grow: 0;
      width: 40%;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 5rem;

    button {
      margin-bottom: 3rem;
    }

    .right {
      width: 100%;
      margin-top: -2rem;
    }
  }
`

export default HeroSection
