import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { BreadCrumb } from '../../components/controls'
import { Page, PageTitle, Section } from '../../components/layout'
import ProductRequestForm from './productRequestForm'

import { productData } from '../../data'

const ProductRequest = ({ className }) => {
  const { upid } = useParams()
  const productName = productData.find((product) => product.upid.value === upid)
    .productName.value
  const paths = [
    {
      path: '/search',
      name: 'Search',
    },
    {
      path: `/search/product/${upid}`,
      name: productName,
    },
    {
      path: `/search/product/${upid}/request`,
      name: 'Request',
    },
  ]
  return (
    <ProductRequestStyled className={className}>
      <Section>
        <BreadCrumb paths={paths} />
      </Section>
      <Section>
        <PageTitle title='Product Request' />
        <ProductRequestForm upid={upid} />
      </Section>
    </ProductRequestStyled>
  )
}

const ProductRequestStyled = styled(Page)``

export default ProductRequest
