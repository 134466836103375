import React from 'react'
import styled from 'styled-components'

import { ProductForm } from '../../components/controls'
import { customBuyerRequest } from '../../data'

const fields = [
  [
    { name: 'productType', width: 4 },
    { name: 'productName', width: 4 },
    { name: 'physicalState', width: 4 },
  ],
  [
    { name: 'physicalForm', width: 3 },
    { name: 'productDescription', width: 9 },
  ],
  [
    { name: 'requestType', width: 4 },
    { name: 'requestDate', width: 4 },
    { name: 'requestQuantity', width: 4 },
  ],
  [
    { name: 'frequency', width: 2 },
    { name: 'storageHandlingCapabilities', width: 3 },
    { name: 'requiredCertifications', width: 3 },
    { name: 'requestedCity', width: 2 },
    { name: 'requestedState', width: 2 },
  ],
  [
    { name: 'functionalCategory', width: 3 },
    { name: 'chemicalClass', width: 3 },
    { name: 'industries', width: 3 },
    { name: 'useCases', width: 3 },
  ],
  [{ name: 'message', width: 12 }],
  [
    { name: 'regulatoryInformation', width: 6 },
    { name: 'buyerFormulationNeeds', width: 6 },
  ],
]

const logisticsFields = [
  { name: 'shippingMethod', width: 4 },
  { name: 'transactionMethod', width: 4 },
  { name: 'productionDate', width: 4 },
]

const NewRequestForm = ({ className }) => {
  return (
    <NewRequestFormStyled
      className={className}
      fields={fields}
      logisticsFields={logisticsFields}
      inputFormat={customBuyerRequest}
    />
  )
}

const NewRequestFormStyled = styled(ProductForm)``

export default NewRequestForm
