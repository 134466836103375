import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useForm, useFieldArray } from 'react-hook-form'

import { Line } from '../../components/layout'
import { Button, useEdit } from '../../components/controls'
import usePage from './usePage'
import Container from './container'
import PricingItem from './pricingItem'
import ConfirmBadge from './confirmBadge'

import { leftFlexColumn, centerFlexRow } from '../../styles/mixins'

const Pricing = ({ className, pricing, accountType, isRequestFinalized }) => {
  const { isConfirmed, versions } = pricing

  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: versions[versions.length - 1],
  })
  const { isEditing, setIsEditing, EditButton } = useEdit()
  const { item: prices, isCurrent, PageControl } = usePage({
    items: versions,
    reset,
    setIsEditing,
  })

  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: 'items',
  })
  const {
    fields: serviceFields,
    append: appendService,
    remove: removeService,
  } = useFieldArray({
    control,
    name: 'services',
  })

  useEffect(() => {
    setIsEditing(false)
    reset(prices)
  }, [prices, reset, setIsEditing])

  const items = itemFields.length ? watch('items', itemFields) : []
  const services = serviceFields.length ? watch('services', serviceFields) : []
  const addPrice = (total, item) => {
    return item.price !== null ? total + Number(item.price) : total
  }
  const total = items.reduce(addPrice, 0) + services.reduce(addPrice, 0)

  const submitHandler = (data) => console.log(data)
  const confirmHandler = () => console.log('confirmed')

  return (
    <PricingStyled
      className={className}
      title='Pricing'
      date={prices.date}
      pageControl={<PageControl />}
      body={
        <form>
          {accountType === 'seller' && isCurrent && !isRequestFinalized && (
            <EditButton onReset={() => reset(prices)} />
          )}
          <div className='list'>
            <div className='listHeader'>
              <p>Items</p>
              {isEditing && (
                <Button
                  text='Add Item'
                  theme='green'
                  onClick={() => appendItem({ item: '', price: null })}
                />
              )}
            </div>
            {itemFields.map((field, i) => (
              <PricingItem
                key={field.id}
                isEditing={isEditing}
                control={control}
                removeHandler={() => removeItem(i)}
                itemName={`items[${i}].item`}
                priceName={`items[${i}].price`}
                itemValue={field.item}
                priceValue={field.price}
              />
            ))}
          </div>
          <div className='list'>
            <div className='listHeader'>
              <p>Services</p>
              {isEditing && (
                <Button
                  text='Add Service'
                  theme='green'
                  onClick={() => appendService({ item: '', price: null })}
                />
              )}
            </div>
            {serviceFields.map((field, i) => (
              <PricingItem
                key={field.id}
                isEditing={isEditing}
                control={control}
                removeHandler={() => removeService(i)}
                itemName={`services[${i}].item`}
                priceName={`services[${i}].price`}
                itemValue={field.item}
                priceValue={field.price}
              />
            ))}
          </div>
          <Line isThicc={false} />
          <div id='total'>
            <span>Total Price</span>
            <span>{`$${total}`}</span>
          </div>
        </form>
      }
      footer={
        <>
          {isConfirmed && <ConfirmBadge accountType='buyer' />}
          {accountType === 'seller' ? (
            <Button
              text='Update Pricing'
              theme='green'
              onClick={handleSubmit(submitHandler)}
              isDisabled={!isDirty}
            />
          ) : (
            <Button
              text='Confirm Pricing'
              theme='green'
              onClick={confirmHandler}
              isDisabled={isRequestFinalized}
            />
          )}
        </>
      }
    ></PricingStyled>
  )
}

const PricingStyled = styled(Container)`
  .list {
    ${leftFlexColumn}

    .listHeader {
      ${centerFlexRow}
      justify-content: space-between;
      width: 100%;
    }
  }

  #total {
    ${centerFlexRow}
    justify-content: space-between;
  }
`

export default Pricing
