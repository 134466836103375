import React from 'react'
import styled from 'styled-components'

import {
  leftFlexRow,
  leftFlexColumn,
  bottomAngleRight,
} from '../../styles/mixins'

import {
  ChartIcon,
  ClockIcon,
  CertificateIcon,
  LightbulbIcon,
} from '../../assets/icons/home'

const details = [
  {
    image: ChartIcon,
    title: 'Consumer Trends',
    description:
      'Keep up with end-consumer demand for sustainably branded goods: naturally-derived, eco-friendly, organic, fair trade, recycled, and more.',
  },
  {
    image: ClockIcon,
    title: 'R&D to Production Time',
    description:
      'Unlock the largest bottleneck in your product development pipeline - finding the most effective and lowest cost raw inputs.',
  },
  {
    image: CertificateIcon,
    title: 'Certifications & Compliance',
    description:
      'Purchasing behavior is increasingly influenced by certification agencies. Find the right inputs to meet the most stringent certification requirements.',
  },
  {
    image: LightbulbIcon,
    title: 'Innovation',
    description:
      'Effortless sourcing is the root of cutting-edge innovation. Find the perfect inputs to build the next generation of sustainable products.',
  },
]

const InnovateSection = ({ className }) => {
  return (
    <InnovateSectionStyled id='innovate' className={className}>
      {' '}
      <div className='content column'>
        <h1>Innovate quickly and differentiate your brand </h1>
        <div className='details'>
          {details.map((detail) => (
            <ImageCard {...detail} />
          ))}
        </div>
      </div>
    </InnovateSectionStyled>
  )
}

const InnovateSectionStyled = styled.div`
  ${bottomAngleRight}
  background-color: var(--color-light-grey);

  .details {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    .details {
      grid-template-columns: 1fr;
    }
  }
`

const ImageCard = ({ className, image, title, description }) => (
  <ImageCardStyled className={className}>
    <div className='imageContainer'>
      <div className='square'>
        <img src={image} />
      </div>
    </div>
    <div className='text'>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </ImageCardStyled>
)

const ImageCardStyled = styled.div`
  ${leftFlexRow}
  align-items: flex-start;

  .imageContainer {
    flex-shrink: 0;
    width: 4rem;

    .square {
      position: relative;
      padding-bottom: 100%;
      overflow: hidden;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .text {
    margin-left: 2rem;

    h3 {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    ${leftFlexColumn}

    .text {
      order: 2;
      margin: 0;
    }

    .imageContainer {
      align-self: center;
      width: 5rem;
      margin-bottom: 2rem;
    }
  }
`

export default InnovateSection
