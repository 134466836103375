import React from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Page, Section } from '../components/layout'
import { Button } from '../components/controls'

import { grid12WithGap } from '../styles/mixins'
import { getLabeledFieldComponents } from '../utils'

const schema = yup.object().shape({
  firstName: yup.string().required(),
  middleInitial: yup.string().max(1),
  lastName: yup.string().required(),
})

const aboutFields = [
  [
    { name: 'firstName', width: 3 },
    { name: 'middleInitial', width: 1 },
    { name: 'lastName', width: 3 },
  ],
  [
    { name: 'email', width: 4 },
    { name: 'phone', width: 3 },
  ],
]

const roleFields = [
  [
    { name: 'jobFunction', width: 3 },
    { name: 'helpWith', width: 3 },
    { name: 'addInfo', width: 6 },
  ],
]

const companyFields = [
  [
    { name: 'company', width: 4 },
    { name: 'industry', width: 3 },
  ],
  [{ name: 'address1', width: 7 }],
  [{ name: 'address2', width: 7 }],
  [
    { name: 'city', width: 3 },
    { name: 'state', width: 1 },
    { name: 'zipCode', width: 3 },
  ],
]

const accountFields = [[{ name: 'accountType', width: 3 }]]

const fieldGroups = [
  {
    title: 'Contact Information',
    fields: aboutFields,
  },
  {
    title: 'Your Role',
    fields: roleFields,
  },
  {
    title: 'Company',
    fields: companyFields,
  },
  {
    title: 'Account Type',
    fields: accountFields,
  },
]

const RegisterPage = ({ className }) => {
  const { handleSubmit, errors, control } = useForm()
  const onSubmit = (data) => console.log(data)

  return (
    <RegisterPageStyled className={className}>
      <Section title='Register for an Account' numRows={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fieldGroups.map((fieldGroup) => (
            <div className='fieldContainer'>
              <h3>{fieldGroup.title}</h3>
              {fieldGroup.fields.map((fieldRow) => (
                <div className='fieldRow'>
                  {getLabeledFieldComponents(fieldRow).map((fieldItem) => {
                    const Component = fieldItem.component
                    const props = fieldItem.props
                    return (
                      <div className={`colSpan${fieldItem.width}`}>
                        <Controller
                          name={props.name}
                          control={control}
                          defaultValue=''
                          render={({ onChange, ...rest }) => {
                            return <Component onChange={onChange} {...props} />
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          ))}
          <Button type='submit' text='Submit' theme='green' />
        </form>
      </Section>
    </RegisterPageStyled>
  )
}

const RegisterPageStyled = styled(Page)`
  .fieldContainer {
    margin-bottom: 2rem;
  }
  .fieldRow {
    ${grid12WithGap}
    margin-bottom: 1rem;
  }
`

export default RegisterPage
