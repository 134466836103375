import React from 'react'
import styled from 'styled-components'

import { ProductForm } from '../../components/controls'
import { buyerRequest } from '../../data'

const fields = [
  [
    { name: 'requestType', width: 4 },
    { name: 'requestDate', width: 4 },
    { name: 'requestQuantity', width: 4 },
  ],
  [
    { name: 'frequency', width: 2 },
    { name: 'storageHandlingCapabilities', width: 3 },
    { name: 'requiredCertifications', width: 3 },
    { name: 'requestedState', width: 2 },
    { name: 'requestedCity', width: 2 },
  ],
  [{ name: 'message', width: 12 }],
  [
    { name: 'regulatoryInformation', width: 3 },
    { name: 'buyerFormulationNeeds', width: 9 },
  ],
]

const logisticsFields = [
  { name: 'shippingMethod', width: 4 },
  { name: 'transactionMethod', width: 4 },
  { name: 'productionDate', width: 4 },
]

const ProductRequestForm = ({ className, upid }) => {
  return (
    <ProductRequestFormStyled
      className={className}
      upid={upid}
      fields={fields}
      logisticsFields={logisticsFields}
      inputFormat={buyerRequest}
    />
  )
}

const ProductRequestFormStyled = styled(ProductForm)``

export default ProductRequestForm
