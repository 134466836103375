import React from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'

import Container from './container'
import { Button, SelectLabeled, Checkbox } from '../../components/controls'

const options = [{ label: 'Complete', value: 'complete' }]

const sellerStatuses = [
  {
    label: 'Payment has been received',
    name: 'paymentReceived',
  },
  {
    label: 'Product has been shipped',
    name: 'productSent',
  },
]

const buyerStatuses = [
  {
    label: 'Payment has been sent',
    name: 'paymentSent',
  },
  {
    label: 'Product has been received',
    name: 'productReceived',
  },
]

const Confirmation = ({ className, accountType }) => {
  const { control, register, handleSubmit } = useForm()
  const submitHandler = (data) => console.log(data)
  const statuses = accountType === 'seller' ? sellerStatuses : buyerStatuses

  return (
    <ConfirmationStyled
      className={className}
      title='Confirmed Order'
      body={
        <>
          <p>
            The order has been confirmed and can no longer be edited. Once you
            receive payment, please mark this request as completed below.
          </p>
          <Controller
            name='status'
            control={control}
            defaultValue={null}
            render={({ onChange, value }) => (
              <SelectLabeled
                className={'select'}
                onChange={onChange}
                value={value}
                options={options}
              />
            )}
          />
          <p>Status</p>
          {statuses.map((status) => (
            <Checkbox
              name={status.name}
              label={status.label}
              ref={register()}
            />
          ))}
        </>
      }
      footer={
        <Button
          text='Update Status'
          theme='green'
          onClick={handleSubmit(submitHandler)}
        />
      }
    />
  )
}

const ConfirmationStyled = styled(Container)`
  width: 100%;

  .select {
    width: 33%;
  }
`

export default Confirmation
