import React from 'react'
import styled from 'styled-components'

const PageTitle = ({ className, title }) => {
  return <PageTitleStyled className={className}>{title}</PageTitleStyled>
}

const PageTitleStyled = styled.h2`
  align-self: flex-start;
  margin-bottom: 2rem;
`

export default PageTitle
