import React from 'react'
import styled from 'styled-components'

const Section = ({ className, title, children }) => {
  return (
    <SectionStyled className={className}>
      {title && <h3>{title}</h3>}
      <div>{children}</div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  box-sizing: border-box;
  width: 100%;

  h3 {
    margin-bottom: 2rem;
  }

  > div {
    > * {
      margin-bottom: 2rem;
    }
  }
`

export default Section
