import React from 'react'
import styled from 'styled-components'

import { isEmpty } from '../../utils'

const DisplayCell = ({ value: initialValue }) => {
  let formattedValue
  const { value, minValue, maxValue, unit } = initialValue || {}
  if (Array.isArray(value)) formattedValue = value.join(', ')
  else if (!isEmpty(value))
    formattedValue = `${value}${!isEmpty(unit) ? ` ${unit}` : ''}`
  else if (!isEmpty(minValue) && !isEmpty(maxValue))
    formattedValue = `${minValue} - ${maxValue}${
      !isEmpty(unit) ? ` ${unit}` : ''
    }`
  else formattedValue = ''

  return <DisplayCellStyled>{formattedValue}</DisplayCellStyled>
}

const DisplayCellStyled = styled.div`
  min-height: 3rem;
`

export default DisplayCell
