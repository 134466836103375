import React, { useState } from 'react'
import styled from 'styled-components'

import { PlainButton } from '../../components/controls'

import { centerFlexRow } from '../../styles/mixins'
import { ChevronDownIcon as ChevronIcon } from '../../assets/icons'

export const usePage = ({ items = [], reset, setIsEditing }) => {
  const totalNumPages = items.length
  const [pageNum, setPageNum] = useState(totalNumPages)
  const isCurrent = pageNum === totalNumPages
  const item = items[pageNum - 1]

  const onPrev = () => {
    reset(items[pageNum - 2])
    setIsEditing(false)
    setPageNum(pageNum - 1)
  }
  const onNext = () => {
    reset(items[pageNum])
    setPageNum(pageNum + 1)
  }

  const PageControl = () => {
    return (
      <PageControlStyled>
        <PlainButton
          className='prev'
          icon={ChevronIcon}
          isDisabled={pageNum === 1}
          onClick={onPrev}
        />
        <span>{`${pageNum} of ${totalNumPages}`}</span>
        <PlainButton
          className='next'
          icon={ChevronIcon}
          isDisabled={pageNum === totalNumPages}
          onClick={onNext}
        />
      </PageControlStyled>
    )
  }

  return { item, isCurrent, pageNum, PageControl }
}

const PageControlStyled = styled.div`
  ${centerFlexRow}
  span {
    margin: 0 0.5rem;
  }

  .prev svg {
    transform: rotate(90deg) scaleX(0.75);
  }

  .next svg {
    transform: rotate(270deg) scaleX(0.75);
  }
`

export default usePage
