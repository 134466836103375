import React from 'react'
import { default as ReactCreatable } from 'react-select/creatable'

import { getValue, getSelected, styles as selectStyles } from './selectUtils'

const Creatable = ({
  onChange,
  blurInputOnSelect = true,
  autoFocus = false,
  className,
  name,
  value,
  defaultValue,
  placeholder,
  isMulti,
  options = [],
  isDisabled = false,
  styles,
}) => {
  return (
    <ReactCreatable
      className={className}
      name={name}
      {...getValue(value)}
      selected={getSelected()}
      defaultValue={defaultValue}
      isMulti={isMulti}
      placeholder={placeholder}
      onChange={onChange}
      blurInputOnSelect={blurInputOnSelect}
      autoFocus={autoFocus}
      options={options}
      isDisabled={isDisabled}
      styles={styles || selectStyles}
    />
  )
}

export default Creatable
