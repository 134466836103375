import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'

import {
  TextareaLabeled,
  Button,
  useEdit,
  useUploadModal,
  FileItem,
} from '../../components/controls'
import Container from './container'
import ConfirmBadge from './confirmBadge'

const Payment = ({ className, payment, accountType, isRequestFinalized }) => {
  const { isConfirmed, date, ...paymentProps } = payment
  const [hasNewFiles, setHasNewFiles] = useState(false)
  const [files, setFiles] = useState(paymentProps.files)

  const { handleSubmit, watch, reset, control } = useForm({
    defaultValues: paymentProps,
  })

  const uploadHandler = (files) => {
    setFiles(files)
    setHasNewFiles(true)
  }

  const { showUploadModal, UploadModal } = useUploadModal({
    onSubmit: uploadHandler,
  })

  const { isEditing, EditButton } = useEdit()

  const formData = watch()
  const isPaymentInformationDirty =
    paymentProps.information !== formData.information

  const submitHandler = (data) => console.log(data)
  const confirmHandler = () => console.log('confirmed')

  return (
    <PaymentStyled
      className={className}
      title='Payment Information'
      date={date}
      body={
        <>
          <form>
            {accountType === 'seller' && !isRequestFinalized && (
              <EditButton
                onReset={() => {
                  reset(paymentProps)
                  setFiles(paymentProps.files)
                  setHasNewFiles(false)
                }}
              />
            )}
            <Controller
              name='information'
              control={control}
              render={({ onChange, value }) => (
                <TextareaLabeled
                  label='Payment Information'
                  value={value}
                  onChange={onChange}
                  isDisabled={!isEditing}
                  isInvisible={!isEditing}
                />
              )}
            />
            <label id='documentationLabel'>Documentation</label>
            {files &&
              files.map((file, i) => <FileItem fileName={file.name} key={i} />)}
            <Button
              text='Upload Payment Document'
              theme='green'
              onClick={showUploadModal}
              isDisabled={!isEditing}
            />
          </form>
          <UploadModal onSubmit={uploadHandler} />
        </>
      }
      footer={
        <>
          {isConfirmed && <ConfirmBadge accountType='buyer' />}
          {accountType === 'seller' ? (
            <Button
              text='Update Payment Info'
              theme='green'
              onClick={handleSubmit(submitHandler)}
              isDisabled={!(hasNewFiles || isPaymentInformationDirty)}
            />
          ) : (
            <Button
              text='Confirm Payment Info'
              theme='green'
              onClick={confirmHandler}
            />
          )}
        </>
      }
    />
  )
}

const PaymentStyled = styled(Container)`
  form {
    > * {
      margin-bottom: 1rem;
    }

    #documentationLabel {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
`

export default Payment
