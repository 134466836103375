import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import ArrowLink from './arrowLink'

import {
  topAngleLeft,
  bottomAngleRight,
  leftFlexColumn,
  leftFlexRow,
} from '../../styles/mixins'
import { ArrowRightIcon } from '../../assets/icons'
import { screensImage } from '../../assets/images'

const RequestSection = ({ className }) => {
  const isTablet = useMediaQuery({
    query: '(max-width: 1200px)',
  })

  return (
    <RequestSectionStyled id='request' className={className}>
      {' '}
      <div className='content row'>
        <div className='left'>
          <h1>Request the perfect custom formulations all in one click.</h1>
          <div>
            <ArrowRightIcon />
            <p>
              <strong>Build custom formulations</strong>
              <br />
              Source more than just off-the-shelf SKUs.
            </p>
          </div>
          <div>
            <ArrowRightIcon />
            <p>
              <strong>No more repetitive cold outreach</strong>
              <br />
              Cut down on back and forth.
            </p>
          </div>
          <div>
            <ArrowRightIcon />
            <p>
              <strong>Automate your workflow</strong>
              <br />
              Streamline RFQ, don’t just put it online.
            </p>
          </div>
          {isTablet && (
            <ArrowLink text='Get Started' link='mailto:info@valdera.com' />
          )}
        </div>
        <div className='right'>
          <img src={screensImage} />
          {!isTablet && (
            <ArrowLink text='Get Started' link='mailto:info@valdera.com' />
          )}
        </div>
      </div>
    </RequestSectionStyled>
  )
}

const RequestSectionStyled = styled.div`
  ${topAngleLeft}
  ${bottomAngleRight}
  background-color: var(--color-white);
  color: var(--color-black);

  path {
    fill: var(--color-black);
  }

  .left {
    ${leftFlexColumn}
    flex-shrink: 0;
    align-self: flex-start;
    width: 40%;

    > div {
      ${leftFlexRow}
      align-items: flex-start;

      :not(:last-child) {
        margin-bottom: 2rem;
      }

      svg {
        margin-top: 0.3rem;
        margin-right: 1rem;
      }
    }

    p {
      font-size: var(--font-size-medium);
    }

    h1 {
      margin-bottom: 3rem;
      font-size: var(--font-size-large);
    }

    a {
      align-self: flex-end;
      margin-top: 2rem;
    }
  }

  .right {
    ${leftFlexColumn}
    width: 50%;

    img {
      width: 60rem;
      height: 30rem;
      object-fit: contain;
      object-position: 0 0;
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 1200px) {
    .right,
    .right > img {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 4rem !important;
    .left p {
      font-size: var(--font-size-small);
    }
  }
`

export default RequestSection
