import React, { useState } from 'react'
import styled from 'styled-components'

import {
  centerFlexRow,
  leftFlexColumn,
  clearLinkFormat,
} from '../../styles/mixins'

const useMobileMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const showMenu = () => setIsMenuVisible(true)
  const hideMenu = () => setIsMenuVisible(false)
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible)

  const MobileMenu = ({ className, headerItems }) => {
    return isMenuVisible ? (
      <MobileMenuStyled className={className}>
        {headerItems.map((item) => (
          <div>
            <a href={item.link} onClick={hideMenu}>
              {item.text}
            </a>
          </div>
        ))}
        <div id='overlay' onClick={hideMenu} />
      </MobileMenuStyled>
    ) : (
      <></>
    )
  }

  return { isMenuVisible, showMenu, hideMenu, toggleMenu, MobileMenu }
}

const MobileMenuStyled = styled.div`
  ${leftFlexColumn}
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  justify-content: flex-start;

  width: auto;
  height: 100vh;
  padding-top: 10rem;

  background-color: var(--color-white);
  color: var(--color-black);

  > div {
    z-index: 99;
    margin: 0;
    padding: 2rem;

    a {
      ${centerFlexRow}
      ${clearLinkFormat}
      margin: 0;
    }
  }

  #overlay {
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  @media screen and (max-width: 767px) {
    padding-top: 6rem;
  }
`

export default useMobileMenu
