import React from 'react'
import styled from 'styled-components'

const Line = ({ className, isThicc = false }) => {
  return <LineStyled className={className} isThicc={isThicc}></LineStyled>
}

const LineStyled = styled.hr`
  width: 100%;
  height: ${(props) => (props.isThicc ? `5px` : `1px`)};
  border-style: none;
  background-color: var(--color-black);
`

export default Line
