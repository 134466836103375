import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import { format } from 'date-fns'

import { useModal, Line } from '../../components/layout'
import {
  Button,
  PlainButton,
  ControlMenu,
  useUploadButton,
} from '../../components/controls'
import UploadForm from './uploadForm'

import { AddIcon } from '../../assets/icons'

const selectOptions = [
  {
    value: 'xxxx1',
    label: 'xxxx1',
  },
  {
    value: 'xxxx2',
    label: 'xxxx2',
  },
]

const uploadModalStyles = css`
  width: 80rem;
  height: 90vh;
`

const ManualUpload = ({ className }) => {
  const {
    showModal: showPropsModal,
    hideModal: hidePropsModal,
    Modal: PropsModal,
  } = useModal()

  const { file, UploadButton } = useUploadButton()
  const [uploadDate, setUploadDate] = useState(null)

  const submitHandler = (data) => {
    hidePropsModal()
    setUploadDate(Date.now())
    console.log(data)
  }

  return (
    <>
      <ManualUploadStyled
        className={className}
        options={[
          {
            label: 'Product Name',
            labelAlign: 'left',
            component: <Select options={selectOptions} />,
            width: 4,
          },
          {
            label: 'Properties',
            labelAlign: 'center',
            component: <PlainButton icon={AddIcon} onClick={showPropsModal} />,
            width: 2,
          },
          {
            label: 'Product Logo',
            labelAlign: 'center',
            component: <UploadButton />,
            width: 2,
          },
          {
            label: 'Additional Information',
            labelAlign: 'center',
            component: <PlainButton icon={AddIcon} />,
            width: 2,
          },
          {
            label: 'Upload Date',
            labelAlign: 'left',
            component: (
              <span className='date'>
                {uploadDate && format(uploadDate, 'PPp')}
              </span>
            ),
            width: 2,
          },
        ]}
      />
      <PropsModal
        title='Properties'
        body={
          <>
            <UploadForm onSubmit={submitHandler} />
          </>
        }
        styles={uploadModalStyles}
      />
    </>
  )
}

const ManualUploadStyled = styled(ControlMenu)`
  .date {
    margin: auto 0;
  }

  button {
    align-self: center;
  }
`

export default ManualUpload
