import React from 'react'
import styled from 'styled-components'

import { PlainButton } from './'

import { centerFlexRow, lightContainer } from '../../styles/mixins'
import { CloseIcon } from '../../assets/icons'

const FileItem = ({ className, key, fileName, onRemove }) => {
  return (
    <FileItemStyled key={key} className={className}>
      {' '}
      <span>{fileName}</span>
      {onRemove && (
        <PlainButton icon={CloseIcon} onClick={() => onRemove?.()} />
      )}
    </FileItemStyled>
  )
}

const FileItemStyled = styled.div`
  ${centerFlexRow}
  ${lightContainer}
  justify-content: space-between;
  height: 2rem;
  margin-top: 0.5rem;
  padding: 0 0.5rem 0 1rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export default FileItem
