import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

import {
  centerFlexColumn,
  leftFlexColumn,
  centerFlexRow,
} from '../../styles/mixins'

const Message = ({ className, name, date, text, file, isUser }) => {
  return (
    <MessageStyled className={className} isUser={isUser}>
      <div className='containerHeader'>
        <span className='name'>{name}</span>
        <span className='name'>{format(new Date(date), 'PP | p')}</span>
      </div>
      <div className='containerBody'>
        {file ? <a href={file.url}>{file.name}</a> : <p>{text}</p>}
      </div>
    </MessageStyled>
  )
}

const MessageStyled = styled.div`
  ${centerFlexColumn}
  width: 75%;
  margin-bottom: 1rem;
  border: solid 1px var(--color-medium-grey);
  background-color: var(--color-white);
  ${(props) => (props.isUser ? `margin-left: auto;` : `margin-right: auto;`)}

  .containerHeader {
    ${centerFlexRow}
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: solid 1px var(--color-medium-grey);
  }

  .containerBody {
    ${leftFlexColumn}
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    padding: 1rem;
    overflow-y: auto;

    p {
      margin: 0;
    }
  }
`

export default Message
