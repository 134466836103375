import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import { Player } from '@lottiefiles/react-lottie-player'
import { InView } from 'react-intersection-observer'

import ArrowLink from './arrowLink'

import { leftFlexRow, leftFlexColumn } from '../../styles/mixins'

import {
  DashboardLottie,
  TargetLottie,
  SecurityLottie,
  CartLottie,
} from '../../assets/lottie'

const suppliers = [
  {
    lottie: SecurityLottie,
    speed: 2,
    style: { transform: 'scale(2.5, 2.5) translateY(1rem)' },
    title: 'Get online, with privacy & protection',
    description:
      'We know keeping your your unique offerings private is crucial for your success. We give you the power to access relevant buyers, without having to make your product catalogue available to the public (and potential competitors). ',
  },
  {
    lottie: TargetLottie,
    speed: 1.5,
    title: 'Reach targeted buyers, fast',
    description:
      'Buyers can make custom product requests through our platform, and we route all relevant requests directly to you. Respond to any and all requests that fit your offering.',
  },
  {
    lottie: CartLottie,
    speed: 0.75,
    title: 'Understand your buyers’ use cases',
    description:
      'We know you offer thousands of formulations. It’s impossible to stay up to date on all their end use cases, applications, and certifications. That’s why we’ll help you label, validate, brand, and market your products to the right buyers. ',
  },
  {
    lottie: DashboardLottie,
    speed: 0.75,
    title: 'Keep your product offering digitally organized and up-to-date',
    description:
      'Standardize the way you store and diseminate product information. Digitizing your catalogues and automating product data management can enable you to proactively respond to demand fluctuations. We want to help you grow your sales efficiently.',
  },
]

const SuppliersSection = ({ className }) => {
  const [indexInView, setIndexInView] = useState(null)

  const onScrollIntoView = (index, inView) => {
    if (inView) {
      setIndexInView(index)
    }
  }

  return (
    <SuppliersSectionStyled id='suppliers' className={className}>
      {' '}
      <div className='content column'>
        <h1>Suppliers & Service Providers</h1>
        <div className='details'>
          <div className='verticalLine'>
            <Fade clear>
              <div />
            </Fade>
          </div>
          {suppliers.map((detail, i) => (
            <ImageDotCard
              isInView={i === indexInView}
              onChange={(inView) => onScrollIntoView(i, inView)}
              {...detail}
            />
          ))}
        </div>
        <ArrowLink
          text='Join our supplier network'
          link='mailto:info@valdera.com'
        />
      </div>
    </SuppliersSectionStyled>
  )
}

const SuppliersSectionStyled = styled.div`
  padding: 16rem;
  background-image: radial-gradient(
    circle farthest-corner at 25% 80%,
    #10484b 0%,
    #000101 100%
  );
  color: var(--color-white);

  a {
    &:hover {
      box-shadow: 0px 2px 0px 0px var(--color-white);
    }
    path {
      fill: var(--color-white);
    }
  }

  .verticalLine {
    width: 0;
    height: 0;

    > div {
      position: absolute;
      width: 0.5rem;
      height: 200vh;
      margin-top: 1rem;
      margin-left: 0.75rem;
      border-radius: 0.25rem;
      background-color: var(--color-white);
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 4rem;

    .verticalLine {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 6rem 2rem;

    .content {
      margin-top: 1rem;
    }
  }
`

const ImageDotCard = ({
  className,
  lottie,
  start,
  end,
  playAll = false,
  isReversed = false,
  speed = 1,
  style,
  title,
  description,
  onChange,
  isInView,
}) => {
  const playerRef = useRef(null)

  const changeHandler = (inView) => {
    onChange(inView)
    if (inView) playerRef.current.play()
  }

  return (
    <InView threshold={0.5} onChange={changeHandler}>
      {({ inView, ref, entry }) => (
        <Fade bottom>
          <ImageDotCardStyled
            ref={ref}
            className={className}
            isInView={isInView}
          >
            <div className='circle' />
            <div className='text'>
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
            <div className='imageContainer'>
              <div className='square'>
                <Player
                  keepLastFrame
                  ref={playerRef}
                  src={lottie}
                  style={style}
                  onEvent={(event) => {
                    if (event === 'load') {
                      playerRef.current.setPlayerSpeed(speed)
                    } else if (event === 'play') {
                      if (!inView) playerRef.current.pause()
                    }
                  }}
                />
              </div>
            </div>
          </ImageDotCardStyled>
        </Fade>
      )}
    </InView>
  )
}

const ImageDotCardStyled = styled.div`
  ${leftFlexRow}
  margin-bottom: 8rem;

  &:last-child {
    margin-bottom: 4rem;
  }

  .imageContainer {
    flex-shrink: 0;
    width: 8rem;
    margin-left: auto;

    .square {
      position: relative;
      height: 0;
      padding-bottom: 100%;
      overflow: visible;

      img {
        position: absolute;
        width: 100%;
        max-height: 50%;
        object-fit: cover;
      }
    }
  }

  .text {
    align-self: flex-start;
    margin: 0.625rem 6rem 0 8rem;

    h3 {
      margin-bottom: 1rem;
    }
  }

  .circle {
    flex-shrink: 0;
    align-self: flex-start;
    width: 2rem;
    height: 2rem;
    transition: ease-in-out 0.5s;
    border-radius: 50%;
    background-color: var(--color-white);
    box-shadow: ${(props) =>
      props.isInView ? '0px 0px 5px 5px rgba(255, 255, 255, 0.5)' : 'none'};
  }

  @media screen and (max-width: 1200px) {
    .text {
      margin-left: 0;
    }

    .circle {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    ${leftFlexColumn}

    .text {
      order: 2;
      margin: 0;
    }

    .imageContainer {
      align-self: center;
      width: 8rem;
      margin: 0;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
  }
`

export default SuppliersSection
